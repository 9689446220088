import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";


export default function ConfidentialityAndPrivacy() {
    window.document.title = "BAM.money | Confidentiality and Privacy";

    return (
        <>  
            <PublicNavbar />
            <PublicTitle title="Confidentiality Notice" />
            <div style={{textAlign: "left", margin: "0 5vw"}}>
                <p>BAM.money will endeavor to maintain the confidentiality of personal information Customer provides in connection with the
                use of the Website and the Content. Nonetheless, Customer acknowledges and agrees that any such information sent via the
                Internet may not be protected and there can be no assurance that such information will remain confidential. With the exception
                of personal information and/or information properly marked as private (i.e., using the tools provided by the system) any
                communication or material Customer posts or transmits to BAM.money over the Internet is, and will be treated as,
                non-confidential and non-proprietary. Customer's posting or transmitting of any unlawful, threatening, libelous, defamatory, obscene,
                pornographic, abusive, discriminatory or profane material, or any material that could constitute or encourage conduct that
                would be considered a criminal offence or violation of any law, is strictly prohibited. In addition, Customer acknowledges
                and agrees that, subject to applicable law, BAM.money may disclose Customer's name and other personal and financial information
                about Customer or Customer's account(s) to its employees, representatives, officers, directors, agents and affiliates, or to
                any governmental or regulatory authority or selfregulatory organization, or to any third-party service provider,
                (i) to comply with applicable laws, rules, orders, subpoenas or requests for information by governmental or regulatory authorities or
                self-regulatory organizations, or (ii) for any other purpose described in any agreement Customer may have with BAM.money.</p>
            </div>
            
            <PublicTitle title="Privacy Policy" />
            <div style={{textAlign: "left", margin: "0 5vw"}}>
                <p>
                    December, 11, 2023
                </p>
                <p>
                    This website is operated by BAM money, Inc. who respect the privacy of visitors to its websites. This Privacy Notice governs the manner in which BAM money collects, uses, maintains and discloses information collected through BAM.money and to any subdomains of this website (“Websites”) or any products and services offered by BAM money.
                
                </p>
                <p>
                    We have provided this Privacy Notice (which may be updated from time to time) to ensure that those who provide personal data to us understand what personal data we may collect and hold about them, what we may use it for and how we keep it safe.  You may have legal rights to access the personal data that we hold about you and to control how we use it which are also explained in this Privacy Policy.  We use the information we collect from you in accordance with this Privacy Policy.  

                    </p>
                <p>
                    Your use of this Website indicates to us that you have read and accept our privacy practices, as outlined in this Privacy Policy. If you have any questions or concerns regarding this Privacy Policy, please contact us at info@bam.money.

                    </p>
                <p>
                    This Privacy Policy is in addition to any other legally binding agreement between you and BAM money that may govern your privacy or use of BAM money products and services. To the extent any such agreement is in writing and conflicts with this Privacy Policy, the terms of such written agreement will govern.

                    </p>
                <p>
                    In general, when users visit our Websites and access information, users will remain anonymous. However, for some services provided through our Websites, such as requesting a product trial or subscribing to email updates, we will ask users to register with an online registration form and to share personal identification information.

                    </p>
                <p>
                    <strong>How to contact us:</strong>
                </p>
                <p>
                    We regularly review our compliance with this Privacy Policy. Questions, comments requests or complaints regarding this Privacy Policy or the Websites are welcomed and should be addressed to geraldo@bam.money. You can also write to us at BAM money, Inc., 2 Park Ave, 20th Floor, New York, NY 10016.

                    </p>
                <p>
                    <strong>What personal information we collect about you and how we use it</strong>
                </p>
                <p>
                    We collect personal information that you provide to us.  There are several ways in which you may share your personal data with us via our Websites, app, online platform or otherwise. You may visit a BAM money event or visit our Websites, respond to a survey, fill out a form, request updates or alerts and in connection with other activities, services, features or resources we make available. In doing so, you may provide your name, email address, mailing address, phone number and other general contact information. You may inquire as to the source of any information that we collect. You may also visit our Websites anonymously without disclosing any personal information. You can also refuse to supply personal information, although it may prevent you from engaging in certain website-related activities. We will retain personal information for as long as it serves the purposes for which the information was collected and for longer periods of time only if required by law or regulations.
                </p>
                <p>
                    In summary, we use this information to:
                </p>
                <ul>
                    <li>Respond to your customer service requests, inquiries, or questions.</li>
                    <li>Provide you with the information, products, and services you request from us.</li>
                    <li>Improve our Website.</li>
                    <li>Run promotions, contests, surveys, or other website features.</li>
                    <li>If you opt-in to our mailing list, you will receive emails that may include company news, updates, related product or service information, etc. If you wish to unsubscribe, detailed instructions are provided at the bottom of each marketing email, or you may contact us via our Websites.</li>
                    <li>Manage and administer our business.</li>
                    <li>Review and improve our products and services.</li>
                </ul>
            </div>
            <PublicFooter />
        </>
    );
}