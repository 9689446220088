import '../css/forbanksmanagers.css';
import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";
import React from 'react';

export default function ForAMs() {
    window.document.title = "BAM.money | For Asset Managers";
    return (
        <>
            <PublicNavbar />
            <PublicTitle title="For Asset Managers" />
            <hr className="content-hr" />
            <div className="home-page-row">
                <div className="hp-heading">Before BAM.Money</div>
                <div className="hp-text">
                    Traditionally, a sales and trading professional would do the following:<br />
                    Use their judgment, skims through markets  and read a few direct and market-related news everyday hoping not to miss any important news, <strong>and</strong><br />
                    Discuss the validity of their internal models, <strong>and</strong><br />
                    Use their systems to check on exposures (including bank exposures and relationships) <strong>and</strong><br />
                    Search for signals of developments that could affect price movements hoping not be behind <strong>and</strong><br />
                    Communicate with their clients and stakeholders
                </div>
            </div>
            <hr className="content-hr" />
            <div className="home-page-row">
                <div className="hp-heading">Things Changed</div>
                <div className="hp-text">
                    Digital transactions and social media may seem old but they are not. However they have changed the market picture. Now, not only is "alt-data" estimated to account for 95%<sup>*</sup> of value in data but it also can change things radically much faster. (Think about a $102B bank run<sup>**</sup> on a medium sized bank caused by social media in only a few weeks).
                    <br />
                    <br />
                    <div className="hp-small-text">* Greenwich Associates: "Future of Trading", 2019<br />
                        ** Washington Post: "First Republic clients pulled $102B" April 24, 2023</div>
                </div>
            </div>

            <hr className="content-hr" />
            <div className="home-page-row">
                <div className="hp-heading">BAM Provides</div>
                <div className="hp-text">
                    For thousands of corporate names, early trading and credit default indicators based on relevant news sentiment, web search traffic volume, price dynamics and more...
                    <br />
                    <ul>
                        <li className="hp-list">Fast and comprehensive news reading and classifications, through relevant news crawling and filtering, ranking and summarizing the critical articles.</li>
                        <li className="hp-list">Consistent searches for early and reliable indicators, through looking for hidden signals and highlighting information that could yield to future price volatility.</li>
                        <li className="hp-list">Similar name dynamics, organized financial ratios.</li>
                        <li className="hp-list">Notification and alert systems that are customizable to clients and stakeholders focus.</li>
                    </ul>
                    <br />
                    <table className="hp-small-text">
                        <tr><td width="50%" style={{ padding: "10px" }}><img className="image hp-image-border" src="/images/forbankmanager/image3.png" style={{ width: "400px", height: "300px", verticalAlign: "bottom" }} alt="" /></td>
                            <td width="50%" style={{ padding: "10px" }}><img className="image hp-image-border" src="/images/forbankmanager/image2.png" style={{ width: "400px", height: "300px", verticalAlign: "bottom" }} alt="" /></td></tr>
                        <tr>
                            <td><em>State of the art backtesting must include in and out of sample when using on the fly signals.</em></td>
                            <td><em>Statistical signals depend on a series of assumptions (e.g., market regimes, asset classes). They also depend on confidence levels.</em></td>
                        </tr>
                        <tr><td width="50%" style={{ padding: "10px" }}><img className="image hp-image-border" src="/images/forbankmanager/image1.png" style={{ width: "400px", height: "300px", verticalAlign: "bottom" }} alt="" /></td></tr>
                        <tr>
                            <td><em>Different Trading Strategies yield different results but backtesting shows when signals are robust or not.</em></td>
                            <td><em></em></td>
                        </tr>
                    </table>
                    <br />
                </div>
            </div>

            <hr className="content-hr" />
            <div className="home-page-row">
                <div className="hp-heading">With BAM.Money</div>
                <div className="hp-text">
                    <strong>PMs can then be freed from mundane activities to improve coverage and act more confidently to:</strong>
                    <br />
                    <ul>
                        <li className="hp-list">Efficiently track market related news without missing any potent news with BAM's tools. Some PMs can save up to 80% of time reading and/or reviewing 10x more articles spending the same time.</li>
                        <li className="hp-list">Rank market news from multiple sources to act more confidently</li>
                        <li className="hp-list">Know what others have been interested in, before that gets into news, to generate alpha</li>
                    </ul>
                    <br />
                    <img className="hp-image-border" src="images/forbankmanager/marketpulse-ss.png" />
                </div>
            </div>
            <PublicFooter />
        </>
    );
}