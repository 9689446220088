import '../css/product.css';
import '../css/header.css';
import { useState, useEffect, useRef } from "react";
import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";
import { AnalystSelectPublic } from '../components/analystSelectModal';
import { Register } from '../components/header';
import { HomePageAnimation } from './home.js'

export function TradersLanding() {
    window.document.title = "BAM.money | For Traders";

    return (
        <>
            <PublicNavbar />
            <div style={{ width: "65%", margin: "auto" }}>
                <h1>Pre-validate stock trades with objective driven-AI</h1>
                <p>
                    <em>“Data is like garbage. You'd better know what you<br />are going to do with it before you collect it.”</em>
                    <br /><strong>- Mark Twain</strong>
                </p>
                <Register text="Try it Free" highlight noDemo />
                <div className="product-article-container">
                    <img src="/images/hans-peter-gauster-3y1zF4hIPCg-unsplash.jpg" alt="" className="truecolor" />
                    <p style={{ fontSize: "10px", margin: "none" }}>Photo by <a href="https://unsplash.com/@sloppyperfectionist">Hans-Peter Gauster</a></p>
                </div>

                <div className="product-article-container">
                    <p>Unlocking unique alpha in today's corporate markets demands a fusion of personal instincts, knowledge, and information. When surpassing conventional wisdom is essential and alpha quickly decays, traders face the challenge of efficiently integrating their instincts and knowledge with additional sources of information. While building an in-house army of data scientists and technologists may be an option for some, nimble firms seek alternatives that address the complexities of acquiring information from third-party providers. BAM allows firms to quickly infuse their own perspectives, trading strategies, holding periods, and data into its objective-driven AI platform to pre-validate stock trades and unlock unparalleled alpha in an ever-evolving market landscape. Let's explore how.</p>
                    <h3>Unique Information</h3>
                    <p>Custom settings and data inputs allow firms to finely tune the AI/ML models to match their unique strategy by adjusting trading horizons, market regimes, pricing, and inputting their firm's own data. This adaptable approach ensures that a firm's alpha potential is maximized effortlessly, eliminating the need for them to generate their own datasets in search of uniqueness.</p>
                </div>
                <Register text="Try it Free" highlight noDemo />
                <div className="product-article-container">
                    <h3>Robust Indicators with Confidence, No Hallucination</h3>
                    <p>Traders quickly validate trades with dynamic equity and credit indicators that include statistical confidence, performance relative to indices, which are updated daily. BAM's Long Short-Term Memory (LSTM) model goes beyond traditional models to process comprehensive data sets with dynamic and frequent retraining to ensure reliability and improvement over time. Indicators are based on excess return performance against relevant asset pools,  confidence levels indicate the model's certainty that a company will outperform its peers based on rigorous backtesting.</p>
                    <h3>Long History of Immutable Results, Straight to the Point</h3>
                    <p>All indicators are fully-transparent and time-stamped in the cloud by an independent third party. They are accessible on request to empower users with an unambiguous view of historical data, signals, and confidence levels. Or to get straight to the point, BAM provides Profit & Loss per signal with short and long history.</p>
                    <img src="/images/page-graphic-modeling.png" alt="" className="truecolor" style={{ height: "auto", objectFit: "contain" }} />
                    <h3>See For Yourself</h3>
                    <p>Firms looking to fight alpha decay with efficient alternatives to an in-house data-centric team should look no further. By infusing their own perspectives, trading strategies, and data into the platform, BAM enables the pre-validation of stock trades, unlocking unparalleled alpha in an ever-evolving market landscape.</p>
                </div>
                <Register text="Try it Free" highlight noDemo />
                <div className="product-article-container">
                    <img src="/images/pierre-bamin-5B0IXL2wAQ0-unsplash.jpg" alt="" className="truecolor" />
                    <p style={{ fontSize: "10px", margin: "none" }}>Photo by <a href="https://unsplash.com/@bamin">Pierre Bamin</a></p>
                </div>
            </div>
            <PublicFooter />
        </>
    );
}

export function NewLanding() {
    window.document.title = "BAM.money";

    return (
        <>
            <PublicNavbar />
            <h1 style={{ fontWeight: "400" }}>Helping You Leverage AI to Build <strong><em>Accurate Proprietary Insights + Strategies</em></strong></h1>
            <div style={{ width: "65%", margin: "auto" }}>

                <div className="landing-pair">
                    <div>
                        <HomePageAnimation />
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                        <h2 style={{ margin: "0" }}>Cumulative Returns</h2>
                        <h3 style={{ margin: "0" }}>Real Market, Signals S&P Data</h3>
                        <img src="images/sp500performance.png" alt="" width="100%" />
                    </div>

                </div>
                <Register text="Try it Free" highlight noDemo />
                <div className="home-display-page" style={{ border: "2px solid var(--airforceblue)", marginBottom: "0" }}>
                    <h2>Engine Features & Use-Cases</h2>
                    <div className="home-display-cols landing-cols">
                        <div>
                            <img src="/images/landing-icon-1.png" alt="" width="20%" class="color-icon" />
                            <h2>Actionable Unique Insights</h2>
                            <h4>Achieve unique alpha from BAM's diverse data sets and strategies or by integrating your own sets. You can also share powerful insights to your stakeholders.</h4>
                        </div>
                        <div>
                            <img src="/images/landing-icon-2.png" alt="" width="20%" class="truecolor" />
                            <h2>No Time or Room to Waste</h2>
                            <h4>You won't miss a beat or need to open a dashboard. BAM's Smart Button integrates advanced information directly into your workflow and environment.</h4>
                        </div>
                        <div>
                            <img src="/images/landing-icon-3.png" style={{ transform: "scale(1.4)" }} alt="" width="20%" class="color-icon" />
                            <h2>Optimal Hedges & Portfolios</h2>
                            <h4>Get ahead of the market with equity and credit signals that include statistical confidence, performance vs indices, natural hedges, similar companies, etc.</h4>
                        </div>
                    </div>
                </div>
                <div className="product-article-container">
                    <h3>Helping You Cut Through the Noise</h3>
                    <p>Artificial Intelligence is hailed as the harbinger of efficiency and informed decision-making in financial markets, but it's not immune to scrutiny and concern is growing. Is AI inadvertently contributing to an escalation in financial noise, creating complexities that hinder rather than aid decision-making? This concern demands a nuanced exploration, weighing the promises of AI against the potential challenges it may pose.
                        The same technologies that promise clarity and insight are contributing to increasing financial noise. The sheer volume of data AI systems process, often including unconventional and alternative sources, presents a double-edged sword. While it opens doors to new insights, it also raises concerns about information overload. Decision-makers grapple with distinguishing between meaningful signals, falsehoods and the cacophony of irrelevant data, potentially leading to confusion, suboptimal decision-making and even wrong decisions.</p>
                    <h3>An Intuitive Dashboard with the Information You Need, Fast</h3>
                    <p>BAM's Intuitive Interface allows you to access the information you want quickly and easily. Custom settings and data inputs allow firms to finely tune our AI/ML models to match their unique strategy by adjusting trading horizons, market regimes, pricing, and inputting their firm's own data. This adaptable approach ensures that a firm's alpha potential is maximized effortlessly, eliminating the need for them to generate their own datasets in search of uniqueness. With BAM, you can allocate your research resources efficiently to focus on the name that meet your criteria, and streamline your risk analysis with relative valuations, similarity dynamics, and custom metrics.</p>
                </div>

                <img src="/images/tradingpulseView.png" alt="" className="truecolor" style={{ borderRadius: "30px", width: "100%" }} />
                <div className="product-article-container">
                    <h3>A No-Code Solution to Build Customized Robust Models</h3>
                    <p>Our data platform allows you to upload your own data and test different strategies to maximize your Alpha. BAM's Long Short-Term Memory (LSTM) model goes beyond traditional models to process comprehensive data sets with dynamic and frequent retraining to ensure reliability and improvement over time. Indicators are based on excess return performance against relevant asset pools, confidence levels indicate the model's certainty that a company will outperform its peers based on rigorous backtesting. We also give you the option to augment our models with your own data. We keep your data and insights secure while allowing you to leverage the computational power and quantitative know-how needed to built sophisticated models. </p>
                    <h3>A Smart Button Giving You the Information You Need When You Need It</h3>
                    <p>Our smart-button seamlessly integrates advanced information directly into your workflow without having to ever open a new dashboard. You can our button once and forever have our signals at your fingertips without consuming valuable screen/real estate. Valuable insights are now accessible at the touch of a button.</p>
                </div>
                <Register text="Try it Free" highlight noDemo />
            </div>
            <PublicFooter />
        </>
    );
}

export function AnalystLanding() {
    window.document.title = "BAM.money";

    return (
        <>
            <PublicNavbar />
            <h1 style={{ fontWeight: "400" }}><strong><em>How Predictive Analytics Helps Corporate Analysts</em></strong></h1>
            <div style={{ width: "65%", margin: "auto" }}>
                <div className="product-article-container">
                    <p>As digital spreadsheets helped proficient accountants, predictive analytics can help financial analysts making top analysts more productive. Three questions - to understand who and how benefits flow- are as follows: 1-  what happens to the marginal productivity of analysts? Marginal productivity is what may cause buyers of analyses pay more for analysis. 2- what does the predictive analysis brings to whom? 3- which predictive analytics (b/c not all are equal)?
                        Good Predictive Analytics should help humans to make better decisions.</p>
                    <p><strong>BAM's predictive analytics does not equip intelligent machines but intelligent analysts.</strong> Analysts benefit when understanding the full set of companies (sectors, etc). Analysts gain when challenge in their thinking. <strong>You test real cases...</strong></p>
                </div>
                <div style={{ height: "300px"}}>
                    <AnalystSelectPublic/>
                </div>
                <div style={{margin: "auto 0"}}>
                <HomePageAnimation topHalf/>
                </div>
                
                <Register text="Try it Free" highlight noDemo />
                <div className="home-display-page" style={{ border: "2px solid var(--airforceblue)", marginBottom: "0" }}>
                    <h2>BAM's Intelligent Engine Is NOT a Substitute For Humans, Rather its features are to help analysts. These are the simple use-cases and features.</h2>
                    <div className="home-display-cols" style={{ padding: "0" }}>
                        <div>
                            <h3 style={{ margin: 0 }}>Use Case</h3>
                        </div>
                        <div>
                            <h3 style={{ margin: 0 }}>Some Features</h3>
                        </div>
                        <div>
                            <h3 style={{ margin: 0 }}>Use Case</h3>
                        </div>
                    </div>
                    <div className="home-display-cols landing-cols" style={{ paddingTop: "0" }}>
                        <div>
                            <img src="/images/landing-icon-1.png" alt="" width="20%" class="color-icon" />
                            <h2>Insights with Accurate Statistical Predictions</h2>
                            <h4>Improve your analytical capability with BAM's engine. It ingests and analyzes vast pools of historical data for over 5k corporates. Diverse data sets and trading strategies to help analysts to see what is not easy with naked eyes in a few seconds.</h4>
                        </div>
                        <div>
                            <img src="/images/landing-icon-2.png" alt="" width="20%" class="truecolor" />
                            <h2>No Time or Room to Waste</h2>
                            <h4>You won't miss a beat or need to open a new dashboard. BAM's Smart Button integrates advanced information directly into your workflow and environment. You can also share powerful insights with your team and stakeholders.</h4>
                        </div>
                        <div>
                            <img src="/images/landing-icon-3.png" style={{ transform: "scale(1.4)" }} alt="" width="20%" class="color-icon" />
                            <h2>Non Obvious Similar Companies</h2>
                            <h4>Many dynamics are not easy to spot, not without proper calculation at least. Get ahead of the market with equity and credit signals that include statistical confidence, performance vs indices, natural hedges, similar companies, etc.</h4>
                        </div>
                    </div>
                </div>
                <div className="product-article-container">
                    <h3>Helping You Cut Through the Noise</h3>
                    <p>Artificial Intelligence is hailed as the harbinger of efficiency and informed decision-making in financial markets, but it's not immune to scrutiny and concern is growing. Is AI inadvertently contributing to an escalation in financial noise, creating complexities that hinder rather than aid decision-making? This concern demands a nuanced exploration, weighing the promises of AI against the potential challenges it may pose.
                        The same technologies that promise clarity and insight are contributing to increasing financial noise. The sheer volume of data AI systems process, often including unconventional and alternative sources, presents a double-edged sword. While it opens doors to new insights, it also raises concerns about information overload. Decision-makers grapple with distinguishing between meaningful signals, falsehoods and the cacophony of irrelevant data, potentially leading to confusion, suboptimal decision-making and even wrong decisions.</p>
                    <h3>An Intuitive Dashboard with the Information You Need, Fast</h3>
                    <p>BAM's Intuitive Interface allows you to access the information you want quickly and easily. Custom settings and data inputs allow firms to finely tune our AI/ML models to match their unique strategy by adjusting trading horizons, market regimes, pricing, and inputting their firm's own data. This adaptable approach ensures that a firm's alpha potential is maximized effortlessly, eliminating the need for them to generate their own datasets in search of uniqueness. With BAM, you can allocate your research resources efficiently to focus on the name that meet your criteria, and streamline your risk analysis with relative valuations, similarity dynamics, and custom metrics.</p>
                    <h3>A No-Code Solution to Build Customized Robust Models</h3>
                    <p>Our data platform allows you to upload your own data and test different strategies to maximize your Alpha. BAM's Long Short-Term Memory (LSTM) model goes beyond traditional models to process comprehensive data sets with dynamic and frequent retraining to ensure reliability and improvement over time. Indicators are based on excess return performance against relevant asset pools, confidence levels indicate the model's certainty that a company will outperform its peers based on rigorous backtesting. We also give you the option to augment our models with your own data. We keep your data and insights secure while allowing you to leverage the computational power and quantitative know-how needed to built sophisticated models. </p>
                    <h3>A Smart Button Giving You the Information You Need When You Need It</h3>
                    <p>Our smart-button seamlessly integrates advanced information directly into your workflow without having to ever open a new dashboard. You can our button once and forever have our signals at your fingertips without consuming valuable screen/real estate. Valuable insights are now accessible at the touch of a button.</p>
                </div>
                <Register text="Try it Free" highlight noDemo />
            </div>
            <PublicFooter />
        </>
    );
}
