import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";
import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Unsubscribe() {
    window.document.title = "BAM.money | Unsubscribe";

    const [message, setMessage] = useState("");
    const [email, setEmail] = useState('');

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
          );
      };

    function handleSubmit(event) {
        event.preventDefault();
        if (!validateEmail(email)) {
            setMessage("Please enter a valid email.");
            return;
        }

        fetch(`/api/account/unsubscribe?email=${encodeURIComponent(email)}`, {})
            .then((r) => {
                window.dataLayer.push({'event': "unsubscribe_email"});
                setMessage(`Successfully Unsubscribed ${email}.`)
                setEmail("");
            })
            .catch(
                (e) => {
                    setMessage("Server Error.");
                }
            )
    }

    return (
        <>  
            <PublicNavbar />
            <PublicTitle title="Unsubscribe Email" />
            <div style={{minHeight: "40vh"}}>
                <h1>Sorry to see you go!</h1>
                <p>Enter your email then submit to unsubscribe</p>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <TextField id="outlined-basic" label="Email" variant="outlined" type="email" value={email} onInput={ e=>setEmail(e.target.value)}/>
                    <br/>
                    <button onClick={handleSubmit}>Unsubscribe from Emails</button>
                    <br/>
                    {message === "" ? null : <p style={{margin: "0 auto"}}>{message}</p>}
                </Box>
            </div>
            <PublicFooter />
        </>
    );
}