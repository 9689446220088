import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import LinkIcon from '@mui/icons-material/Link';

export function AnalystSelectPublic() {
    const [searchNameField, setSearchNameField] = useState("");
    const [searchTickerField, setSearchTickerField] = useState("");

    const [data, setData] = useState([]);

    const tickers = ['', 'DLTR', 'NUE', 'BAX', 'FICO', 'DG', 'SHW', 'TGT', 'PTLO', 'XPOF', 'TH', 'PACB', 'ADBE', 'AAPL']
    const [analysts, setAnalysts] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`/api/analyst/search_analysts_list_public`);
                const responseData = await response.json();
                setAnalysts(responseData);
            } catch {
                return
            }
        }
        fetchData()
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`/api/analyst/search_public?name=${searchNameField}&ticker=${searchTickerField}`);
                const responseData = await response.json();
                setData(responseData);
            } catch {
                return
            }
        }
        fetchData()

    }, [searchTickerField, searchNameField]);

    return (
        <Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                <Autocomplete
                    sx={{ width: 200 }}
                    value={searchNameField}
                    onChange={(event, newValue) => {
                        setSearchNameField(newValue ? newValue.label : '');
                        setSearchTickerField('');
                    }}
                    options={[...analysts.map((item) => {
                        return {
                            'label': item.name
                        }
                    }), { label: '' }]}
                    renderInput={(params) => <TextField {...params} label="Analyst" />}
                    disabled={searchTickerField != ''}
                />
                <Autocomplete
                    sx={{ width: 200 }}
                    value={searchTickerField}
                    onChange={(event, newValue) => {
                        setSearchTickerField(newValue ? newValue.label : '');
                        setSearchNameField('');
                    }}
                    options={tickers.map((item) => {
                        return {
                            'label': item
                        }
                    })}
                    renderInput={(params) => <TextField {...params} label="Ticker" />}
                    disabled={searchNameField != ''}
                />
            </Box>
                {data.name &&
                    <>
                    <p>
                        {data.name} performed with {Math.round(data.dn_specific.bayesian_accuracy*100)}% accuracy on {data.dn_specific.ticker}
                    </p>
                        <p>
                            Meanwhile BAM was able to obtain an accuracy of {Math.round(data.dn_specific.bayesian_accuracy_bam*100)}% under the same situation 
                        </p>
                    </>
                }
        </Box>
    )

}

export function AnalystSelect() {
    const [searchNameField, setSearchNameField] = useState("");
    const [searchTickerField, setSearchTickerField] = useState("");

    const [data, setData] = useState([]);


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`/api/analyst/search?name=${searchNameField}&ticker=${searchTickerField}`);
                const responseData = await response.json();
                const parsedData = responseData.map((item) => {
                    return {
                        ...item,
                        'publishedDate': new Date(item.price_targets.publishedDate),
                    }
                });
                setData(parsedData);
            } catch {
                return
            }
        }
        fetchData()
    }, [searchTickerField, searchNameField]);

    const handleSearchNameChange = async (event) => {
        setSearchNameField(event.target.value);
    };
    const handleSearchTickerChange = async (event) => {
        setSearchTickerField(event.target.value);
    };
    return (
        <Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <TextField
                    sx={{ flex: 1, margin: '20px' }}
                    label="Analyst"
                    value={searchNameField}
                    onChange={handleSearchNameChange}
                />
                <TextField
                    sx={{ flex: 1, margin: '20px' }}
                    label="Ticker"
                    value={searchTickerField}
                    onChange={handleSearchTickerChange}
                />
            </Box>
            {
                data.length > 0 &&
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {['Analyst', 'Date', 'Ticker', 'Price target', 'Price when posted', 'Title', 'URL'].map((rowName) => (
                                    <TableCell>{rowName}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Link to={`/analyst/${row.name}`} style={{ color: 'black', textDecoration: 'none' }}>
                                            {row.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell component="th" scope="row">{row.publishedDate.toLocaleDateString('en-US')}</TableCell>
                                    <TableCell component="th" scope="row">
                                        <Link to={`/tradingpulse/search/company/${row.news._id}`} style={{ color: 'black', textDecoration: 'none' }}>
                                            {row.price_targets.symbol}
                                        </Link>
                                    </TableCell>
                                    {['priceTarget', 'priceWhenPosted', 'newsTitle'].map((fieldName) => (
                                        <TableCell component="th" scope="row">
                                            {row.price_targets[fieldName] ? row.price_targets[fieldName].toString() : ""}
                                        </TableCell>
                                    ))}
                                    <TableCell component="th" scope="row">
                                        <a href={row.price_targets.newsURL} style={{ color: 'black' }}>
                                            <LinkIcon />
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>
    )

}


export function AnalystSelectModal() {
    const [open, setOpen] = useState(false);

    return (<>
        <Button onClick={(_) => { setOpen(true) }}>Analyst Select</Button>
        <Modal
            open={open}
            onClose={(_) => { setOpen(false) }}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
            }}

        >
            <Box sx={{
                width: "70%",
                backgroundColor: "white",
                display: 'flex',
                flexDirection: 'column',
                padding: "40px",
                borderRadius: "30px",
                textAlign: "center",
                maxHeight: "80vh",
                overflowY: "auto"
            }}>
                <AnalystSelect />
            </Box>
        </Modal>
    </>);
}
