import '../css/profile.css';
import { useEffect, useState } from "react";

import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";

import { FaFileCode, FaTrash } from "react-icons/fa6";

export default function Profile() {
  window.document.title = "BAM.money | Profile";

  const [profile, setProfile] = useState(null);
  const [rows, setRows] = useState([]);


  const getProfile = async () => {
    fetch("/api/account/profile")
      .then((response) => response.json())
      .then((json) => {
        setProfile(json);
        setRows(json.trading ? json.trading : []);
      });
  };

  useEffect(() => {
    getProfile()
  }, []);

  function downloadData() {
    fetch(`/api/account/datadump`, {
      method: "GET"
    }).then(res => {
      if (res.ok) {
        return res.blob();
      } else {
        throw new Error('Failed to retrieve file.');
      }
    }).then(blob => {
      
      const filename = 'bam_data_download.json';
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }).catch(error => {
      alert(`Error Downloading Data:\n${error}`)
    });
  }


  return (
    <>
      <PublicNavbar />
      <PublicTitle title="Profile" />
      <div className="profile-info-table-container">
        <table className="profile-info-table">
          <tbody>
            <tr>
              <td>Name</td>
              <td>{profile ? (profile.first_name + " " + profile.last_name) : "N/A"}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{profile ? profile.email : "N/A"}</td>
            </tr>
            <tr>
              <td>Subscription</td>
              <td>{profile ? profile.role : "N/A"}</td>
            </tr>
            <tr>
              <td>Valid Until</td>
              <td>{profile ? (new Date(profile.valid_until)).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" }) : "N/A"}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button onClick={downloadData}><FaFileCode />Export My Data</button>
      {/*<button><FaTrash />Delete My Account (Requests Deletion which should be completed within 48 hrs)</button>*/}
      <PublicFooter />
    </>
  );
};
