import '../css/marketpulse.css';
import React, { useState, useEffect, useReducer, useRef } from 'react';
import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle.js";
import {
    Routes,
    Route,
    useLocation,
    useParams,
    useNavigate
} from "react-router-dom";
import Modal from 'react-modal'
import { FaCircleInfo, FaBell, FaDownload, FaSort, FaSortUp, FaSortDown, FaRegStar, FaStar, FaReceipt } from 'react-icons/fa6';
import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';

import D3PNL from '../d3Charts/d3PNL.js';
import D3NewsFlow from '../d3Charts/d3NewsFlow.js';
import D3Sentiment from '../d3Charts/d3Sentiment';
import { MarketpulseTopBar } from '../components/marketpulseComponents/MarketpulseTopBar';
import { SECTable } from '../components/marketpulseComponents/SECTable.js';
import { SimilarityTable } from '../components/marketpulseComponents/SimilarityTable.js';
import SearchCompany from './specificCompany.js';

import { FeedbackModal } from '../components/FeedbackModal.js';
import { UserVoteButton } from '../components/marketpulseComponents/UserVoteButton.js';

import Drawer from '@mui/material/Drawer';
import Slider from '@mui/material/Slider'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TablePagination from '@mui/material/TablePagination';

import { useMediaQuery, useTheme } from '@mui/material';



export function MarketpulseRow(props) {
    const [signal, setSignal] = useState("N/A");
    const [signalColor, setSignalColor] = useState("check-dot-gray");
    const [signalConfidence, setSignalConfidence] = useState("N/A");
    const [eqSignal, setEqSignal] = useState("N/A");
    const [eqSignalColor, setEqSignalColor] = useState("check-dot-gray");
    const [eqSignalConfidence, setEqSignalConfidence] = useState("N/A");

    //variables for the data in the charts
    const [newsFlowData, setNewsFlowData] = useState([0, 0]);
    const [pnlRawData, setPNLRawData] = useState([]);
    const [pnlData, setPNLData] = useState([]);
    const [sentimentData, setSentimentData] = useState([]);

    const [ssDate, setSSDate] = useState("01-01-1970");
    const [insiderTradingData, setInsiderTradingData] = useState({ "previous_year_percent": 0, "current_year_percent": 0, "previous_year_shares": [0, 0], "current_year_shares": [0, 0] });
    const [equity, setEquity] = useState(null);
    const [finratio, setFinRatio] = useState(null);
    const [credit, setCredit] = useState(null);
    const [similarETF, setSimilarETF] = useState(null);
    const [similarity, setSimilarity] = useState(null);
    const [riskMetrics, setRiskMetrics] = useState(null);
    const [antiRisk, setAntiRisk] = useState(null);
    const [companyName, setCompanyName] = useState(props.ticker);
    const [secData, setSECData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [sentimentRange, setSentimentRange] = useState([-1, 1]);
    const [EIOption, setEIOption] = useState("buy");
    const [EIConfidence, setEIConfidence] = useState(50);
    const [CIOption, setCIOption] = useState("buy");
    const [CIConfidence, setCIConfidence] = useState(50);
    const [sentimentNotificationsChecked, setSetimentNotificationsChecked] = useState(false);
    const [eiNotificationsChecked, setEiNotificationsChecked] = useState(false);
    const [ciNotificationsChecked, setCiNotificationsChecked] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [showUserVote, setShowUserVote] = useState(false);
    const [cumulativePNL, setCumulativePNL] = useState(null);
    const [topFeature, setTopFeature] = useState("N/A");
    const [hp, setHp] = useState(localStorage.getItem("hp") ? localStorage.getItem("hp") : 15);
    const [bestHorizon, setBestHorizon] = useState({
        'ei': {
            'horizon': '1d',
            'confidence': 0.5
        },
        'ci': {
            'horizon': '1d',
            'confidence': 0.5
        }
    });
    const [analysisArticle, setAnalysisArticle] = useState(null);

    const [chartType, setChartType] = useState(null);

    const navigate = useNavigate();

    const isMobile = props.isMobile;
    const mobilePage = props.mobilePage;
    const isETF = props.onlyShowETFs;

    const { selectedOption, selectedOption2, selectedOption3, period, setPeriod, stateIndicator, stateBHorizon } = props;



    function parseSum(sum) {
        if (sum) {
            return sum.replaceAll("COMMA_REPLACEMENT_STR", ",");
        } else {
            return "(no summary)"
        }
    }

    //parsing sentiment data
    const parseSentiData = (inputData) => {
        if (!inputData.hsum) inputData.hsum = Array(30).fill("(no summary)");
        if (!inputData.asum) inputData.asum = Array(30).fill("(no summary)");
        if (!inputData.lsum) inputData.lsum = Array(30).fill("(no summary)");

        const data = [];
        const length = inputData.high.length;
        const days = [];
        const endDate = new Date(inputData.ssdate);
        for (let i = 0; i < length; i++) {
            const currentDate = new Date(endDate);
            currentDate.setDate(endDate.getDate() - i);
            days.push(currentDate);
        }

        for (let i = 0; i < length; i++) {
            var high = (inputData.high[i] === 10 ? null : inputData.high[i]);
            var avg = (inputData.avg[i] === 10 ? null : inputData.avg[i]);
            var low = (inputData.low[i] === 10 ? null : inputData.low[i]);

            if (high < avg) {
                high = (1 + 4 * avg) / 5;
            }
            if (low > avg) {
                low = (-1 + 4 * avg) / 5;
            }
            data.push({
                date: (new Date(days[i])).toISOString(), // Convert date to "YYYY-MM-DD" format
                high,
                avg,
                low,
                hlink: inputData.hlink[i],
                alink: inputData.alink[i],
                llink: inputData.llink[i],
                hsum: parseSum(inputData.hsum[i]),
                asum: parseSum(inputData.asum[i]),
                lsum: parseSum(inputData.lsum[i])
            });
        }
        return data;
    };


    function initializeRow(json, regimeSettings, hp, period, onlyShowETFs) {
        setIsFavorite(json.isFavorite === "true");


        const getNestedProperty = (obj, path) => {
            return path.split('.').reduce((acc, part) => {
                // Handle array indices in the path
                const match = part.match(/(\w+)\[(\d+)\]/);
                if (match) {
                    acc = acc[match[1]];
                    acc = acc[parseInt(match[2], 10)];
                } else {
                    acc = acc[part];
                }
                return acc;
            }, obj);
        };


        const getAdjustedCumulativePNL = (columnName) => {
            let columnField = columnName;
            let suffix = '';


            // Adjust column name based on hp for pnl
            if (columnName === "pnl15") {

                columnField = "cumulative_pnls"

                if (regimeSettings === "bullish") {
                    suffix += ".hp15_bull";
                } else if (regimeSettings === "bearish") {
                    suffix += ".hp15_bear";
                }
                else {
                    suffix += ".hp15"
                }


                // Adjust the start date based on the selected period
                switch (period) {
                    case "2m":
                        suffix += ".cumulative_pnl_2m"
                        break;
                    case "4m":
                        suffix += ".cumulative_pnl_4m"
                        break;
                    case "6m":
                        suffix += ".cumulative_pnl_6m"
                        break;
                    case "All":
                        suffix += ".cumulative_pnl_All"
                        break;
                    default:
                        suffix += ".cumulative_pnl_2m"
                }
                columnField = `${columnField}${suffix}[${Math.floor(props.threshold * 100) - 55}]`;

            }
            return columnField;
        };

        try {
            const sentimentObject = {
                high: json.high_score_latest30,
                hlink: json.high_link_latest30,
                hsum: json.high_summary_latest30,
                avg: json.avg_score_latest30,
                alink: json.avg_link_latest30,
                asum: json.avg_summary_latest30,
                low: json.low_score_latest30,
                llink: json.low_link_latest30,
                lsum: json.low_summary_latest30,
                ssdate: json.SS_Date
            }
            setSentimentData(parseSentiData(sentimentObject));

            setSSDate(json.SS_Date);

            if (json.articles_count_latest30)
                setNewsFlowData(json.articles_count_latest30);
        }
        catch {
            console.log()
        }

        if (json.insider_trading_yearly_percent_change) {
            setInsiderTradingData(json.insider_trading_yearly_percent_change)
        }
        if (json.debtor) {
            setCompanyName(json.debtor);
        }

        if (json.similarity) {
            setSimilarity(json.similarity);
            if (json.similarity.PRICE && json.similarity.PRICE !== "N/A") {
                setEquity(json.similarity.PRICE[0])
            }
            if (json.similarity.COMB && json.similarity.COMB !== "N/A") {
                setFinRatio(json.similarity.COMB[0])
            }
            if (json.similarity.BONDS && json.similarity.BONDS !== "N/A") {
                setCredit(json.similarity.BONDS[0])
            }
            if (json.similarity.ETF_PRICE && json.similarity.ETF_PRICE !== "N/A") {
                setSimilarETF(json.similarity.ETF_PRICE[0])
            }
        }

        if (json.anti_risk_dn_10) {
            setAntiRisk(json.anti_risk_dn_10);
        }

        if (json.risk) {
            setRiskMetrics(json.risk);
        }


        if (json.sec_data) {
            setSECData(json.sec_data);
        }

        if (json.analysis && Array.isArray(json.analysis) && json.analysis.length > 0) {

            setAnalysisArticle({
                url: `/analysis/article/${json.analysis[0].analysis_id}`,
                date: (new Date(json.analysis[0].date)).toISOString().split("T")[0]
            })
        }


        if (!json.lstm) return

        var ei = null
        var ci = null

        // old 15 or 5 suffix
        //var suffix = (hp > 10 ? "15" : "5")
        var suffix = "15" //always show hp15
        var ssuffix = "_iis"
        if (regimeSettings === "bullish") {
            suffix += "_bull"
            ssuffix += "_bull"
        } else if (regimeSettings === "bearish") {
            suffix += "_bear"
            ssuffix += "_bear"
        }

        let hpp = hp
        if (hp > 30) {
            ssuffix = ssuffix.substring(4) + '_longterm'
            hpp = hp / 5 - 5
        }
        try {
            if (!Number.isNaN(json.lstm["ci" + ssuffix][hpp - 1])) ci = json.lstm["ci" + ssuffix][hpp - 1];
            if (!Number.isNaN(json.lstm["ei" + ssuffix][hpp - 1])) ei = json.lstm["ei" + ssuffix][hpp - 1];
        }
        catch {
            ci = 0.50;
            ei = 0.50;
        }
        console.log(json);
        if (json.intepret_confidence && json.interpret_confidence.features && Array.isArray(json.interpret_confidence.features) && json.interpret_confidence.features.length > 0) {
            setTopFeature(json.interpret_confidence.features[0]);
        }

        // setting the pnl data based on the period
        if (json.pnl_arr) {
            const endDate = new Date();
            let startDate = new Date(endDate);

            switch (period) {
                case "2m":
                    startDate.setMonth(endDate.getMonth() - 2);
                    break;
                case "4m":
                    startDate.setMonth(endDate.getMonth() - 4);
                    break;
                case "6m":
                    startDate.setMonth(endDate.getMonth() - 6);
                    break;
                case "All":
                    startDate = new Date(0); // Start from the earliest possible date
                    break;
                default:
                    startDate.setMonth(startDate.getMonth() - 2);
            }


            // need to change suffix to take into account hp properly
            try {
                const path = getAdjustedCumulativePNL('pnl15')
                const rawValue = eval(`json.pnl_arr.${path}`);
                const cumulative_pnl = Math.round(rawValue * 10000) / 10000;
                console.log(rawValue);
                const pnlDataArrayRaw = json.pnl_arr["hp" + suffix];
                const pnlDataArray = [];
                for (let i = 0; i < pnlDataArrayRaw.date.length; i++) {
                    pnlDataArray.push({
                        date: pnlDataArrayRaw.date[i],
                        ei: pnlDataArrayRaw.ei[i],
                        price: pnlDataArrayRaw.price[i],
                        release_date: pnlDataArrayRaw.rdate[i],
                        return: pnlDataArrayRaw.return[i],
                    })
                }
                const filteredPNL = pnlDataArray.filter((entry) => {
                    let entryDate = new Date(entry.date);
                    return entryDate >= startDate && entryDate <= endDate;
                });

                setPNLData(filteredPNL);
                setCumulativePNL(cumulative_pnl);
            } catch (error) {
                console.log(error)
            }

        }
        setShowUserVote(json.price_target_count ? (json.price_target_count > 0) : false);

        if (!Number.isNaN(ci)) {
            setSignalConfidence((Math.abs(ci) * 100).toFixed(1));
            if (ci > props.threshold) {
                setSignalColor("check-dot-green");
                setSignal("Buy");
            } else if (ci < -props.threshold) {
                setSignalColor("check-dot-red");
                setSignal("Sell");
            } else {
                setSignalColor("check-dot-yellow");
                setSignal("Hold");
            }
        }
        if (!Number.isNaN(ei)) {
            setEqSignalConfidence((Math.abs(ei) * 100).toFixed(1));
            if (ei > props.threshold) {
                setEqSignalColor("check-dot-green");
                setEqSignal("Buy");
            } else if (ei < -props.threshold) {
                setEqSignalColor("check-dot-red");
                setEqSignal("Sell");
            } else {
                setEqSignalColor("check-dot-yellow");
                setEqSignal("Hold");
            }
        }
        // Update bestHorizon
        try {
            let ciMax = json.lstm["ci" + ssuffix].slice((hp > 30) ? 1 : 0).reduce((accum, currentValue) => Math.abs(currentValue) < Math.abs(accum) ? accum : currentValue, 0);
            let eiMax = json.lstm["ei" + ssuffix].slice((hp > 30) ? 1 : 0).reduce((accum, currentValue) => Math.abs(currentValue) < Math.abs(accum) ? accum : currentValue, 0);
            let ciMaxIndex = json.lstm["ci" + ssuffix].findIndex((v) => v == ciMax);
            let eiMaxIndex = json.lstm["ei" + ssuffix].findIndex((v) => v == eiMax);
            setBestHorizon({
                'ei': {
                    'confidence': eiMax,
                    'horizon': (hp > 30) ? `${eiMaxIndex + 1}m` : `${eiMaxIndex + 1}d`
                },
                'ci': {
                    'confidence': ciMax,
                    'horizon': (hp > 30) ? `${ciMaxIndex + 1}m` : `${ciMaxIndex + 1}d`
                }
            })
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        initializeRow(props.data, props.regimeSettings, props.hp, period)
    }, [props.data, props.regimeSettings, props.threshold, props.hp, period]);

    function clickSEC() {
        setChartType("SEC");
    }

    function clickPNL() {
        setChartType("PNL");
    }

    function clickSentiment() {
        setChartType("Sentiment");
    }

    function clickNewsFlow() {
        setChartType("NewsFlow");
    }

    function clickSimilarity() {
        setChartType("Similarity");
    }

    function clickedOutModal() {
        setChartType(null);
    }

    const handleChildElementClick = (e) => {
        e.stopPropagation()
    }

    function clickAddNotification() {
        setShowModal(true);
    }

    function handleNotifications(event) {
        event.preventDefault();
        const sentimentRangeRequest = sentimentNotificationsChecked ? sentimentRange : [-1, -1];
        const EIOptionRequest = eiNotificationsChecked ? (EIOption === "N/A" ? "buy" : EIOption) : "";
        const EIConfidenceRequest = eiNotificationsChecked ? EIConfidence : "";
        const CIOptionRequest = ciNotificationsChecked ? (CIOption === "N/A" ? "buy" : CIOption) : "";
        const CIConfidenceRequest = ciNotificationsChecked ? CIConfidence : "";
        fetch(`/api/account/notifications?ticker=${encodeURIComponent(props.ticker)}&sentiment_low=${sentimentRangeRequest[0]}&sentiment_high=${sentimentRangeRequest[1]}&ei_option=${EIOptionRequest}&ei_min_confidence=${EIConfidenceRequest}&ci_option=${CIOptionRequest}&ci_min_confidence=${CIConfidenceRequest}`, {
            method: "POST"
        })
        setShowModal(false);
    }

    function closeModal() {
        setShowModal(false);
    }

    const handleSentimentRangeChange = (event, newSentimentRange) => {
        setSentimentRange(newSentimentRange);
    };

    function clickAddFavorites() {
        fetch(`/api/account/favorites?ticker=${encodeURIComponent(props.ticker)}`, {
            method: "POST"
        })
        setIsFavorite(true);
    }

    function clickRemoveFavorites() {
        fetch(`/api/account/favorites?ticker=${encodeURIComponent(props.ticker)}`, {
            method: "DELETE"
        })
        setIsFavorite(false);
    }

    function truncateToLastTwoDecimals(number) {
        const strNumber = number.toString();
        const index = strNumber.lastIndexOf(".");
        if (index === -1) {
            return number; // No decimal point found
        }
        return strNumber.slice(0, index + 3); // Keep only two digits after the decimal point
    }

    function downloadHistorical() {
        fetch(`/api/data/public-full-hist-ss?debtor=${encodeURIComponent(props.debtor)}`, {
            method: "GET"
        }).then(res => {
            if (res.ok) {
                return res.blob();
            } else {
                throw new Error('Failed to retrieve file.');
            }
        }).then(blob => {
            const name = (props.ticker != "") ? props.ticker : props.companyName
            const filename = name + '.csv';
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }).catch(error => {
            alert("You don't have access to the historical data of this Debtor")
        });
    }

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-40%, -40%)',
        },
    };

    function redirectCompany() {
        if (!isETF) {
            navigate(`/tradingpulse/search/company/${props.data._id}`);
        }
    }

    function redirectETF() {
        if (isETF) {
            navigate(`/tradingpulse/search/etf/${props.data._id}`);
        }
    }
    return (
        <div className="mktps-row">
            {!isETF && (
                <div className="mktps-col obligor" style={{ display: "block" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-content={companyName || "N/A"} onClick={redirectCompany}>
                    <p style={{ marginBottom: "0" }}>{props.ticker || "N/A"}</p>
                    <p style={{ fontSize: "8px", marginTop: "0" }}>{companyName && props.ticker ? companyName : "N/A"}</p>
                </div>
            )}
            {isETF && (
                <div className="mktps-col obligor" style={{ display: "block" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-content={companyName || "N/A"} onClick={redirectETF}>
                    <p style={{ marginBottom: "0" }}>{props.ticker || "N/A"}</p>
                    <p style={{ fontSize: "8px", marginTop: "0" }}>{companyName && props.ticker ? companyName : "N/A"}</p>
                </div>
            )}
            {(!props.isMobile || (props.isMobile && props.mobilePage === 1)) && (
                <div className="mktps-col signals">
                    <div style={{ display: "flex", width: "100%" }}>
                        {(stateIndicator === "CI" || stateIndicator === "Both") && (
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                    {stateBHorizon === "Display Best Horizon" && bestHorizon && bestHorizon['ci'] ? (
                                        <svg style={{ margin: 'auto', height: 20, width: 50 }}>
                                            <rect x="2" y="1" rx="10" ry="10" width="46" height="18" style={{ fill: `rgba(var(${bestHorizon['ci']['confidence'] < 0 ? '--red-rgb' : '--green-rgb'}), 0.9)` }}></rect>
                                            <text x="25" y="14" text-anchor="middle" style={{ fontSize: "11px", fill: "black" }}>{bestHorizon['ci']['horizon'] || "N/A"}</text>
                                        </svg>
                                    ) : null}
                                    <span className={`mktps-check-dot mktps-${signalColor}`} data-tooltip-id="mktps-header-tooltip" data-tooltip-html={signalConfidence != "N/A" ? `Credit Indicator:<br /><strong>${signal} (${signalConfidence}%)</strong>` : "N/A"}>
                                        <p>CI</p>
                                    </span>
                                </div>
                            </div>
                        )}
                        {(stateIndicator === "EI" || stateIndicator === "Both") && (
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                    {stateBHorizon === "Display Best Horizon" && bestHorizon && bestHorizon['ei'] ? (
                                        <svg style={{ margin: 'auto', height: 20, width: 50 }}>
                                            <rect x="2" y="1" rx="10" ry="10" width="46" height="18" style={{ fill: `rgba(var(${bestHorizon['ei']['confidence'] < 0 ? '--red-rgb' : '--green-rgb'}), 0.9)` }}></rect>
                                            <text x="25" y="14" text-anchor="middle" style={{ fontSize: "11px", fill: "black" }}>{bestHorizon['ei']['horizon'] || "N/A"}</text>
                                        </svg>
                                    ) : null}
                                    <span className={`mktps-check-dot mktps-${eqSignalColor}`} data-tooltip-id="mktps-header-tooltip" data-tooltip-html={eqSignalConfidence != "N/A" ? `Equity Indicator:<br /><strong>${eqSignal} (${eqSignalConfidence}%)</strong>` : "N/A"}>
                                        <p>EI</p>
                                    </span>
                                </div>
                                {
                                    !isETF &&
                                    <UserVoteButton
                                        dn_id={props.data._id}
                                        hp={props.hp}
                                        ticker={props.ticker}
                                        regime={props.regimeSettings}
                                        isEI={true}
                                        similarity={similarity}
                                        riskMetrics={riskMetrics}
                                        sentimentData={sentimentData}
                                        ptLink={`/tradingpulse/search/company/${props.data._id}#price-targets`}
                                        disabled={!showUserVote}
                                    />
                                }
                                {
                                    topFeature != "N/A" && <p style={{fontSize: "8px"}}>{topFeature}</p>
                                }
                            </div>
                        )}
                    </div>
                </div>
            )}
            {selectedOption === 'tradingpnl' && (!props.isMobile || (props.isMobile && props.mobilePage === 2)) && (
                <div className="mktps-col pnl" onClick={clickPNL}>
                    <D3PNL data={pnlData || "N/A"} cumulativePNL={cumulativePNL} threshold={props.threshold} ticker={props.ticker} small />
                </div>
            )}
            {selectedOption === 'sentiment' && (!props.isMobile || (props.isMobile && props.mobilePage === 2)) && (
                <div className="mktps-col sentiment" onClick={clickSentiment}>
                    <D3Sentiment data={sentimentData || "N/A"} insider_trading_data={insiderTradingData || "N/A"} small />
                </div>
            )}
            {selectedOption === 'newsflow' && (!props.isMobile || (props.isMobile && props.mobilePage === 2)) && (
                <div className="mktps-col newsflow" onClick={clickNewsFlow}>
                    <D3NewsFlow small data={newsFlowData || "N/A"} date={ssDate || "N/A"} />
                </div>
            )}
            {selectedOption3 === "risk" && (!props.isMobile || (props.isMobile && props.mobilePage === 3)) && (
                <div className="mktps-col riskmetrics" style={{ display: "block", fontSize: "10px", position: "relative" }} onClick={clickPNL}>
                    {riskMetrics ? (
                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%" }}>
                            {riskMetrics.VaR?.["Percentage Cutoff"] && riskMetrics.VaR?.Amount ? (
                                <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-html={`Value At Risk<br />Percentage Cutoff: ${riskMetrics.VaR["Percentage Cutoff"]}`}>
                                    VaR: <span style={{ fontSize: "14px", margin: "0", color: "black" }}>{riskMetrics.VaR.Amount}%</span>
                                </p>
                            ) : (
                                <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip">N/A</p>
                            )}
                            {riskMetrics.PTT?.["Max Timespan (trading days)"] && riskMetrics.PTT?.Amount && riskMetrics.PTT?.["Time Period"]?.Start && riskMetrics.PTT?.["Time Period"]?.End ? (
                                <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-html={`Peak to Trough<br />${riskMetrics.PTT["Max Timespan (trading days)"]} trading day max timespan<br /> Time Period: ${riskMetrics.PTT["Time Period"].Start} to ${riskMetrics.PTT["Time Period"].End}`}>
                                    PTT: <span style={{ fontSize: "14px", margin: "0", color: "black" }}>{riskMetrics.PTT.Amount}%</span>
                                </p>
                            ) : (
                                <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip">N/A</p>
                            )}
                            {riskMetrics.ES?.["Percentage Cutoff"] && riskMetrics.ES?.Amount ? (
                                <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-html={`Expected Shortfall<br />Percentage Cutoff: ${riskMetrics.ES["Percentage Cutoff"]}`}>
                                    ES: <span style={{ fontSize: "14px", margin: "0", color: "black" }}>{riskMetrics.ES.Amount}%</span>
                                </p>
                            ) : (
                                <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip">N/A</p>
                            )}
                        </div>
                    ) : (
                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%" }}>
                            <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip">N/A</p>
                        </div>
                    )}
                </div>
            )}
            {selectedOption3 === "sharp" && (!props.isMobile || (props.isMobile && props.mobilePage === 3)) && (
                <div className="mktps-col riskmetrics" style={{ display: "block", fontSize: "10px", position: "relative" }} onClick={clickPNL}>
                    {riskMetrics ? (
                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%" }}>
                            {period && riskMetrics.price_sharpe_ratio && riskMetrics.price_sharpe_ratio[period] ? (
                                <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip">
                                    Passive ({period}): <span style={{ fontSize: "14px", margin: "0", color: "black" }}>
                                        {truncateToLastTwoDecimals(riskMetrics.price_sharpe_ratio[period])}
                                    </span>
                                </p>
                            ) : (
                                <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip">
                                    Passive: N/A
                                </p>
                            )}
                            {period && riskMetrics.model_sharpe_ratio && riskMetrics.model_sharpe_ratio[period] ? (
                                <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip">
                                    Active ({period}): <span style={{ fontSize: "14px", margin: "0", color: "black" }}>
                                        {truncateToLastTwoDecimals(riskMetrics.model_sharpe_ratio[period])}
                                    </span>
                                </p>
                            ) : (
                                <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip">
                                    Active: N/A
                                </p>
                            )}
                        </div>
                    ) : (
                        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%" }}>
                            <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip">
                                Sharpe Ratio: N/A
                            </p>
                        </div>
                    )}
                </div>
            )}
            {selectedOption2 === 'similarcos' && (!props.isMobile || (props.isMobile && props.mobilePage === 3)) && (
                <div className="mktps-col similar" style={{ display: "block", fontSize: "10px", position: "relative" }} onClick={clickSimilarity}>
                    <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%" }}>
                        {similarETF?.ticker && similarETF?.value && (
                            <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Correlation: ${similarETF.value.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]}`}>
                                ETF: <span style={{ fontSize: "14px", margin: "0", color: "black" }}>{similarETF.ticker}</span>
                            </p>
                        )}
                        {equity?.ticker && equity?.value && (
                            <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Correlation: ${equity.value.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]}`}>
                                Equity: <span style={{ fontSize: "14px", margin: "0", color: "black" }}>{equity.ticker}</span>
                            </p>
                        )}
                        {finratio?.ticker && finratio?.value && (
                            <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Correlation: ${finratio.value.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]}`}>
                                Fin-Ratio: <span style={{ fontSize: "14px", margin: "0", color: "black" }}>{finratio.ticker}</span>
                            </p>
                        )}
                        {credit?.ticker && credit?.value && (
                            <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Correlation: ${credit.value.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]}`}>
                                Credit: <span style={{ fontSize: "14px", margin: "0", color: "black" }}>{credit.ticker}</span>
                            </p>
                        )}
                        {antiRisk && antiRisk[0][0] && antiRisk[0][1] && (
                            <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Combined VaR: ${antiRisk[0][1].toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] + "%"}`}>
                                VaR-type Hedge: <span style={{ fontSize: "14px", margin: "0", color: "black" }}>{antiRisk[0][0]}</span>
                            </p>
                        )}
                    </div>
                </div>

            )}
            {selectedOption2 === 'relval' && (!props.isMobile || (props.isMobile && props.mobilePage === 3)) && (
                <div className="mktps-col similar" style={{ display: "block", fontSize: "10px", position: "relative" }} onClick={clickSimilarity}>
                    <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%" }}>
                        {similarity && similarity.relative_value ? (
                            <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Correlation: ${equity.value ? equity.value.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : "N/A"}, ${similarity.relative_value.isSameDirection ? "Same Direction" : "Opposite Direction"}`}>
                                Relative Value: <span style={{ fontSize: "14px", margin: "0", color: "black" }}>{similarity.PRICE[similarity.relative_value.array_index].ticker}</span>
                            </p>
                        ) : (
                            <p style={{ margin: "0" }} data-tooltip-id="mktps-header-tooltip">
                                Relative Value: N/A
                            </p>
                        )}
                    </div>
                </div>
            )}
            {props.isMobile && (
                <div className="mktps-col next" style={{ display: 'flex', flexDirection: 'row' }}>
                    {props.mobilePage === 1 ? (
                        <button onClick={() => props.setMobilePage(props.mobilePage - 1)} className="mktps-back-next-button-mobile" disabled> &lt; </button>
                    ) : (
                        <button onClick={() => props.setMobilePage(props.mobilePage - 1)} className="mktps-back-next-button-mobile"> &lt; </button>
                    )}
                    {props.mobilePage === 3 ? (
                        <button onClick={() => props.setMobilePage(props.mobilePage + 1)} className="mktps-back-next-button-mobile" disabled> &gt; </button>
                    ) : (
                        <button onClick={() => props.setMobilePage(props.mobilePage + 1)} className="mktps-back-next-button-mobile"> &gt; </button>
                    )}
                </div>
            )}
            {!isMobile && (
                <div className="mktps-col actions">
                    <div style={{ margin: "auto" }}>
                        {!isFavorite && <FaRegStar data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Add ${props.ticker} to favorites`} onClick={clickAddFavorites} />}
                        {isFavorite && <FaStar data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Remove ${props.ticker} from favorites`} onClick={clickRemoveFavorites} />}
                    </div>
                    <div style={{ margin: "auto" }}>
                        {analysisArticle && <FaReceipt data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Read Analysis About ${props.ticker} Written on ${analysisArticle.date}`} onClick={() => navigate(analysisArticle.url)} />}
                    </div>
                    <div style={{ margin: "auto" }}>
                        <FaBell data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Set up Notifications for ${props.ticker}`} onClick={clickAddNotification} />
                        <Modal isOpen={showModal} onRequestClose={closeModal} style={customModalStyles} contentLabel="Notifications set up modal">
                            <h2>Add notifications for {props.ticker || "N/A"}</h2>
                            <form onSubmit={handleNotifications}>
                                <div className="sentiment-range">
                                    <Box sx={{ width: 600 }}>
                                        <Checkbox
                                            checked={sentimentNotificationsChecked}
                                            onChange={(event) => setSetimentNotificationsChecked(event.target.checked)}
                                        />
                                        Add email subscription for sentiment range {sentimentRange[0] || "N/A"} to {sentimentRange[1] || "N/A"}:
                                        <Box sx={{ width: 480, px: 8 }}>
                                            <Slider
                                                getAriaLabel={() => 'Sentiment range'}
                                                value={sentimentRange || [0, 0]}
                                                onChange={handleSentimentRangeChange}
                                                getAriaValueText={(value) => `${value}`}
                                                min={-1}
                                                max={1}
                                                step={.01}
                                            />
                                        </Box>
                                    </Box>
                                </div>
                                <div>
                                    <label>
                                        <Checkbox
                                            checked={ciNotificationsChecked}
                                            onChange={(event) => setCiNotificationsChecked(event.target.checked)}
                                        />
                                        Get notified by CI direction:
                                        <select onInput={(event) => setCIOption(event.target.value)}>
                                            <option value="buy">buy</option>
                                            <option value="hold">hold</option>
                                            <option value="sell">sell</option>
                                        </select> with at least confidence <input type="text" value={CIConfidence || "N/A"} onChange={(event) => setCIConfidence(event.target.value)} style={{ width: 30 }} />%
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <Checkbox
                                            checked={eiNotificationsChecked}
                                            onChange={(event) => setEiNotificationsChecked(event.target.checked)}
                                        />
                                        Get notified by EI direction:
                                        <select onInput={(event) => setEIOption(event.target.value)}>
                                            <option value="buy">buy</option>
                                            <option value="hold">hold</option>
                                            <option value="sell">sell</option>
                                        </select> with at least confidence <input type="text" value={EIConfidence || "N/A"} onChange={(event) => setEIConfidence(event.target.value)} style={{ width: 30 }} />%
                                    </label>
                                </div>
                            </form>
                            <div style={{ display: "flex" }}>
                                <button onClick={closeModal}>close</button>
                                <button onClick={handleNotifications} style={{ marginLeft: "auto" }}>submit</button>
                            </div>
                        </Modal>
                    </div>
                    <div style={{ margin: "auto" }}>
                        <FaDownload data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`Download Historical Data for ${props.ticker}`} onClick={downloadHistorical} />
                    </div>
                </div>
            )}
            {chartType && (
                <div style={{ position: "fixed", top: "0", left: "0", height: "100vh", width: "100vw", backgroundColor: "rgba(0,0,0,0.3)", zIndex: "100" }} onClick={clickedOutModal}>
                    <div className="mktps-chart-modal" onClick={(e) => handleChildElementClick(e)}>
                        <div className="mktps-chart-modal-selector">
                            <h3>{props.ticker || "N/A"} | {companyName || "N/A"}</h3>
                            {selectedOption === 'tradingpnl' && (
                                <button className={chartType === "PNL" ? "highlighted-button" : ""} onClick={clickPNL} data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Profit & Loss resulting from clear execution based on signals. The trading rule is simple and straightforward: buy/green dot (or sell/red) at the opening based on the signal on day 1, then sell (or buy) at the closing of the holding period (5 or 15 days). Repeat this process each day, making a buy (or sell) decision, followed by a 5 or 15-day wait, and then execute a sell (or buy) at the closing.</p>">Trading PNL</button>
                            )}
                            {!isETF && selectedOption === 'sentiment' && (
                                <button className={chartType === "Sentiment" ? "highlighted-button" : ""} onClick={clickSentiment} data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Sentiment Scores measure the machine's sentiment about a company in digital media, like news, blogs, and web posts. There are three scores: one for the most positive sentiment, one for the most negative sentiment, and one that's the average of all the relevant news about the company. The scores range from -1.0 (very negative sentiment) to +1.0 (very positive sentiment). To calculate these scores, BAM uses a BERT-style NLP model. But before using the model, BAM filters through a large portion of the web, around 70% or more, from over 50,000 sources, based on what's available on Google. This filtering helps make the sentiment scores as accurate as possible.</p>">
                                    Sentiment
                                </button>
                            )}
                            {!isETF && selectedOption === 'newsflow' && (
                                <button className={chartType === "NewsFlow" ? "highlighted-button" : ""} onClick={clickNewsFlow} data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Volume of news regarding a company relative to itself during the course of 30 days. Normalized to maximum over last 30 days.</p>">
                                    News Flow
                                </button>
                            )}
                            {!isETF && <button className={chartType === "SEC" ? "highlighted-button" : ""} onClick={clickSEC} data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>SEC Filing data compiled on a quarterly and yearly basis.</p>">Fin Ratio</button>}
                            <button className={chartType === "Similarity" ? "highlighted-button" : ""} onClick={clickSimilarity} data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Similar companies are those that perform in similar ways. BAM figures out this similarity in three ways: by looking at their stocks,  bonds, and financial ratios. For each of these, it uses different time periods and peer groupings to calculate correlation of normalized dynamics.</p>">Similarity</button>
                        </div>
                        <div className="mktps-chart-modal-container">
                            {chartType === "SEC" ? <SECTable data={secData || "N/A"} sector={props.data.sector} sicCode={props.data.SICCode} index={props.data.Index} /> : null}
                            {chartType === "PNL" && selectedOption === 'tradingpnl' ? <D3PNL data={pnlData || "N/A"} cumulativePNL={cumulativePNL} threshold={props.threshold} /> : null}
                            {chartType === "Sentiment" && selectedOption === 'sentiment' ? <D3Sentiment data={sentimentData || "N/A"} insider_trading_data={insiderTradingData || "N/A"} /> : null}
                            {chartType === "NewsFlow" && selectedOption === 'newsflow' ? <D3NewsFlow data={newsFlowData || "N/A"} date={ssDate || "N/A"} /> : null}
                            {chartType === "Similarity" ? <SimilarityTable data={similarity || "N/A"} antiRiskData={antiRisk || "N/A"} searchFunction={props.searchFunction} /> : null}
                        </div>
                    </div>
                </div>
            )}
        </div>


    );
}

export function OverallRow(props) {

    const [chartType, setChartType] = useState(null);
    function clickPNL() {
        setChartType("PNL");
    }
    function clickedOutModal() {
        setChartType(null);
    }

    const [pnlData, setPNLData] = useState([]);
    function initializeRow(data, period) {
        if (!data || data.length == 0) {
            return
        }

        const endDate = new Date();
        let startDate = new Date(endDate);

        // Adjust the start date based on the selected period
        switch (period) {
            case "2m":
                startDate.setMonth(endDate.getMonth() - 2);
                break;
            case "4m":
                startDate.setMonth(endDate.getMonth() - 4);
                break;
            case "6m":
                startDate.setMonth(endDate.getMonth() - 6);
                break;
            case "All":
                startDate = new Date(0); // Start from the earliest possible date
                break;
            default:
                startDate.setMonth(endDate.getMonth() - 2);
        }

        const filteredPNL = data.filter((entry) => {
            let entryDate = new Date(entry.date);
            return entryDate >= startDate && entryDate <= endDate;
        });

        setPNLData(filteredPNL);
    }

    useEffect(() => {
        initializeRow(props.data, props.period)
    }, [props.data, props.period, props.cumulativePNLArray]);
    return (
        <div>
            {(!props.isMobile || props.isMobile && props.mobilePage === 1) &&
                <div onClick={clickPNL} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p id="rcorners2">
                        <p style={{ margin: "0" }}>Overall PNL (evenly weighted)
                            {/*<FaCircleInfo data-tooltip-id="mktps-header-tooltip" data-tooltip-content="Confidence-weighted sum of all PNLs of results. Includes results on all pages." />*/}
                        </p>
                        <div style={{ flexDirection: 'column', transform: 'translate(+5%, 0%)', alignSelf: 'center', justifyContent: 'center' }}>
                            <D3PNL data={pnlData} cumulativePNLArray={props.cumulativePNLArray} threshold={props.threshold} is_overall={true} small />
                        </div>
                    </p>
                </div>}

            {chartType ?
                <div style={{ position: "fixed", top: "0", left: "0", height: "100vh", width: "100vw", backgroundColor: "rgba(0,0,0,0.3)", zIndex: "100" }} onClick={clickedOutModal}>
                    <div className="mktps-chart-modal">
                        <div className="mktps-chart-modal-container">
                            {chartType === "PNL" ?
                                <>
                                    <h3 style={{ position: "absolute", left: "50%", transform: "translate(-50%)" }}>Overall PNL (evenly weighted)</h3>
                                    <D3PNL data={pnlData} cumulativePNLArray={props.cumulativePNLArray} threshold={props.threshold} is_overall={true} />
                                </>
                                : null}
                        </div>
                    </div>
                </div>
                : null
            }
        </div>

    );

}

export function MarketpulseTable(props) {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [overallPnl, setOverallPnl] = useState([]);
    const [currentPage, setCurrentPage] = useState(localStorage.getItem("current-page") ? parseInt(localStorage.getItem("current-page")) : 1);
    const [searchQuery, setSearchQuery] = useState(localStorage.getItem("search-query") ? localStorage.getItem("search-query") : "");
    var defaultIndices = [];
    try { defaultIndices = JSON.parse(localStorage.getItem("selected-indices")); } catch { }
    const [selectedIndices, setSelectedIndices] = useState(defaultIndices ? defaultIndices : []);
    var defaultSectors = [];
    try { defaultSectors = JSON.parse(localStorage.getItem("selected-sectors")); } catch { }
    const [selectedSectors, setSelectedSectors] = useState(defaultSectors ? defaultSectors : []);
    const [regimeSettings, setRegimeSettings] = useState(localStorage.getItem("regime") ? localStorage.getItem("regime") : "neutral");
    const [sortedColumn, setSortedColumn] = useState(localStorage.getItem("sorted-column") ? localStorage.getItem("sorted-column") : null);
    const [sortOrder, setSortOrder] = useState(localStorage.getItem("sort-order") ? localStorage.getItem("sort-order") : "asc");
    useEffect(() => {
        localStorage.setItem("sorted-column", sortedColumn)
        localStorage.setItem("sorted-order", sortOrder)
        localStorage.setItem("selected-indices", JSON.stringify(selectedIndices))
        localStorage.setItem("selected-sectors", JSON.stringify(selectedSectors))
        localStorage.setItem("current-page", currentPage)
        localStorage.setItem("search-query", searchQuery)
    }, [sortedColumn, sortOrder, selectedIndices, selectedSectors, currentPage, searchQuery])
    const [onlyShowFavorites, setOnlyShowFavorites] = useState(localStorage.getItem("only-show-fav") && localStorage.getItem("only-show-fav") == "true" ? true : false);
    const [displayCount, setDisplayCount] = useState(localStorage.getItem("displaycount") ? localStorage.getItem("displaycount") : 10); // debtors per page default 10
    const [favorites, setFavorites] = useState([]);

    const [cumulativePNLArray, setCumulativePNLArray] = useState([]);

    const [selectedModel, setSelectedModel] = useState(0);
    const [models, setModels] = useState([{ 'id': "0", 'model_name': 'BAM' }]);

    const [threshold, setThreshold] = useState(localStorage.getItem("threshold") ? localStorage.getItem("threshold") : 55.0);
    const [minPrice, setMinPrice] = useState(localStorage.getItem("minprice") ? localStorage.getItem("minprice") : 1.0);

    const [hp, setHp] = useState(localStorage.getItem("hp") ? localStorage.getItem("hp") : 15);
    const [hpRaw, setHpRaw] = useState(hp);
    const [maxPage, setMaxPage] = useState(100);
    const [nameCount, setNameCount] = useState(0);
    const [onlyShowETFs, setOnlyShowETFs] = useState(localStorage.getItem("only-show-etf") && localStorage.getItem("only-show-etf") == "true" ? true : false);
    const [stateSort, setStateSort] = useState(localStorage.getItem("col-state-sort") ? localStorage.getItem("col-state-sort") : "Default");
    const [stateIndicator, setStateIndicator] = useState(localStorage.getItem("col-state-indicator") ? localStorage.getItem("col-state-indicator") : "EI");
    const [stateBHorizon, setStateBHorizon] = useState(localStorage.getItem("col-state-BHorizon") ? localStorage.getItem("col-state-BHorizon") : "Hide");

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSumPNL, setIsLoadingSumPNL] = useState(true);

    //setting the period from the slider
    const [period, setPeriod] = useState(localStorage.getItem("historical-period") ? localStorage.getItem("historical-period") : "2m");

    const latestQueryId = useRef(0); // this is used to ensure the last fetch called is the one applied to the component
    const latestSumQueryId = useRef(0);
    // out of order data was causing issues with searches taking different times when typed out quickly

    const marks = [
        {
            value: 55.0,
            label: '55%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];
    const hpMarks = []
    for (let value = 1; value <= 30; value++) {
        let label = '';
        if (value === 1 || value === 30) {
            label = `${value}d`;
        }
        hpMarks.push({ value, label })
    }
    for (let value = 35; value <= 55; value += 5) {
        let label = `${value / 5 - 5}m`;
        hpMarks.push({ value, label })

    }

    const getAdjustedCumulativePNL = (columnName) => {
        let columnField = columnName;
        let suffix = '';


        // Adjust column name based on hp for pnl
        if (columnName === "pnl15") {

            columnField = "pnl_arr.cumulative_pnls"

            if (regimeSettings === "bullish") {
                suffix += ".hp15_bull";
            } else if (regimeSettings === "bearish") {
                suffix += ".hp15_bear";
            }
            else {
                suffix += ".hp15"
            }


            // Adjust the start date based on the selected period
            switch (period) {
                case "2m":
                    suffix += ".cumulative_pnl_2m"
                    break;
                case "4m":
                    suffix += ".cumulative_pnl_4m"
                    break;
                case "6m":
                    suffix += ".cumulative_pnl_6m"
                    break;
                case "All":
                    suffix += ".cumulative_pnl_All"
                    break;
                default:
                    suffix += ".cumulative_pnl_2m"
            }
            columnField = `${columnField}${suffix}`;

        }
        return columnField;
    };

    useEffect(() => {
        const queryId = ++latestQueryId.current;
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setData([]);
                const encodedIndices = selectedIndices.map(filter => encodeURIComponent(filter));
                const indicesParam = encodedIndices.length > 0 ? `&indices=${encodedIndices.join(',')}` : '';
                const encodedSectors = selectedSectors.map(filter => encodeURIComponent(filter));
                const sectorsParam = encodedSectors.length > 0 ? `&sectors=${encodedSectors.join(',')}` : '';
                const onlyShowFavoritesParam = onlyShowFavorites ? "true" : "false";
                const onlyShowETFsParam = onlyShowETFs ? "true" : "false";

                const debtorsPerPage = `&limit=${displayCount}`;
                const minPriceParam = `&min_price=${minPrice.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}`;

                //sort order direction, if it is a dash set it to descending else leave it ascending
                const sortOrderDirection = sortOrder === "asc" ? "" : "-"
                //string that goes into the URL request
                const sortParam = sortedColumn !== null ? `&sort=${sortOrderDirection + sortedColumn}` : '';
                let defSort = '-iis_score';
                switch (stateSort) {
                    case "Default":
                        defSort = '-iis_score';
                        break;
                    case "1 Year":
                        defSort = "pttr1y"
                        break;
                    case "6 Months":
                        defSort = "pttr6m"
                        break;
                    case "3 Months":
                        defSort = "pttr3m"
                        break;
                    case "1 Month":
                        defSort = "pttr1m"
                        break;
                    case "7 Days":
                        defSort = "pttr7d"
                        break;
                    case "1 Day":
                        defSort = "pttr1d"
                        break;
                    default:
                        break;
                }

                let response = ""
                if (selectedModel == '0') {
                    response = await fetch(`/api/marketpulse?search=${searchQuery}&page=${currentPage}${indicesParam}${sectorsParam}${sortParam}${minPriceParam}${debtorsPerPage}&show_favorites=${onlyShowFavoritesParam}&sort_def=${defSort}&show_etfs=${onlyShowETFsParam}&sumPNL=false`);
                } else {
                    response = await fetch(`/api/marketpulse/get_custom?search=${searchQuery}&page=${currentPage}${indicesParam}${sectorsParam}${sortParam}${minPriceParam}${debtorsPerPage}&show_favorites=${onlyShowFavoritesParam}&model_id=${selectedModel}&show_etfs=${onlyShowETFsParam}&sumPNL=false`);
                }
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                if (selectedModel == '0') {
                    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

                    async function processStream() {
                        var buffer = "";
                        var tmpDataArr = [];
                        while (true) {
                            const { done, value } = await reader.read();
                            if (done && buffer.length < 200) {
                                setIsLoading(false);
                                break;
                            }
                            if (queryId === latestQueryId.current) {
                                if (value) buffer += value;

                                let open = 0;
                                let close = 0;
                                //buffer = buffer.slice(i)
                                let i = 0;
                                for (i = 0; i < buffer.length; i++) {
                                    if (buffer[i] === '{') {
                                        open++;
                                    } else if (buffer[i] === '}') {
                                        close++;
                                    }
                                    if (open == close) {
                                        break;
                                    }
                                }
                                if (i < buffer.length) {
                                    try {
                                        const match = buffer.slice(0, i + 1)
                                        const obj = JSON.parse(match);
                                        buffer = buffer.slice(i + 1)
                                        if ("count" in obj) {
                                            setMaxPage(Math.ceil(obj.count / displayCount))
                                            setNameCount(obj.count)
                                        } else {
                                            setData(tmpDataArr.concat([obj]))
                                            tmpDataArr.push(obj)
                                            setIsLoading(false);
                                        }
                                    } catch (e) {
                                        console.log(e)
                                        continue;
                                    }
                                }
                            } else {
                                break;
                            }
                        }
                    }

                    processStream();
                } else {
                    const responseData = await response.json();
                    if (responseData.response == "2-days access used up") {
                        alert("2-days access used up for this month")
                        window.location.href = "/subscription";
                    }
                    if (queryId === latestQueryId.current) {
                        setIsLoading(false);
                        setData(responseData.data);
                        setMaxPage(responseData.max_page);
                        setNameCount(responseData.count)
                    }
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        window.dataLayer.push({ 'event': 'tradingpulse_access' });
    }, [searchQuery, currentPage, selectedIndices, selectedSectors, sortedColumn, sortOrder, onlyShowFavorites, onlyShowETFs, displayCount, minPrice, selectedModel, stateSort]);

    useEffect(() => {
        const sumQueryId = ++latestSumQueryId.current;
        const fetchData = async () => {
            try {
                setIsLoadingSumPNL(true);
                const encodedIndices = selectedIndices.map(filter => encodeURIComponent(filter));
                const indicesParam = encodedIndices.length > 0 ? `&indices=${encodedIndices.join(',')}` : '';
                const encodedSectors = selectedSectors.map(filter => encodeURIComponent(filter));
                const sectorsParam = encodedSectors.length > 0 ? `&sectors=${encodedSectors.join(',')}` : '';
                const onlyShowFavoritesParam = onlyShowFavorites ? "true" : "false";
                const onlyShowETFsParam = onlyShowETFs ? "true" : "false";
                const minPriceParam = `&min_price=${minPrice.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}`;

                if (sumQueryId === latestSumQueryId.current) {
                    const cum_pnl_query = getAdjustedCumulativePNL('pnl15');
                    const response = await fetch(`/api/marketpulse?search=${searchQuery}${indicesParam}${sectorsParam}${minPriceParam}&show_favorites=${onlyShowFavoritesParam}&show_etfs=${onlyShowETFsParam}&sumPNL=true&cumPNL=${cum_pnl_query}`);
                    console.log(cum_pnl_query);
                    const responseData = await response.json();
                    setOverallPnl(responseData.sum_pnl);
                    setCumulativePNLArray(responseData.cumulative_pnl);
                    setIsLoadingSumPNL(false);
                }
            } catch (error) {
                setIsLoadingSumPNL(false);
                console.error('Error fetching data:', error);
            }
        }
        console.log(overallPnl, cumulativePNLArray);
        fetchData();
    }, [searchQuery, selectedIndices, selectedSectors, onlyShowFavorites, onlyShowETFs, minPrice, period])

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const response = await fetch(`/api/account/favorites`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                setFavorites(responseData.favorites);
            } catch (error) {
                console.error("Error fetching favorites:", error);
            }
        }
        fetchFavorites();
        const fetchModels = async () => {
            try {
                const response = await fetch(`/api/custom_model/get_owned`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                setModels([{ 'id': "0", 'model_name': 'BAM' }].concat(responseData.models));
            } catch (error) {
                console.error("Error fetching favorites:", error);
            }
        }
        fetchModels();
    }, []);

    // only run on updates after first render
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            setCurrentPage(1);
        } else {
            didMount.current = true;
        }
    }, [searchQuery, selectedIndices, selectedSectors, sortedColumn, sortOrder, displayCount, minPrice, selectedModel]);

    const handlePageChange = (newPage) => {
        var pageToBeSet = newPage
        if (newPage < 1) {
            pageToBeSet = 1
        }
        if (newPage > maxPage) {
            pageToBeSet = maxPage
        }
        if (pageToBeSet != currentPage) {
            setCurrentPage(pageToBeSet);
            window.scrollTo(0, 0);
        }

    };

    // Dropdown function for the three charts, including an option
    // to select the time for the trading pnl
    const DropdownChart = ({ selectedOption, handleChange }) => {
        return (
            <select
                value={selectedOption}
                onChange={handleChange}
                style={{
                    padding: '0px',
                    borderRadius: '5px',
                    border: '1px solid white',
                    fontSize: '16px',
                    color: 'black',
                    margin: '10px 0',
                    width: '100%',
                    maxWidth: '400px',
                    backgroundColor: 'transparent'
                }}
            >
                <option value="tradingpnl">Hist. Active PNL</option>
                <option value="sentiment">Insider & Sentiment</option>
                <option value="newsflow">News Flow</option>
            </select>
        );
    };


    // Dropdown function for the similar companies and relative value, including an option
    // to select the time for the trading pnl
    const DropdownRVSC = ({ selectedOption2, handleChange2 }) => {
        return (
            <select
                value={selectedOption2}
                onChange={handleChange2}
                style={{
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid white',
                    fontSize: '16px',
                    color: 'black',
                    margin: '10px 0',
                    width: '100%',
                    maxWidth: '300px',
                    backgroundColor: 'transparent'
                }}
            >
                <option value="similarcos">Similar Companies</option>
                <option value="relval">Relative Value</option>
            </select>
        );
    };

    // Dropdown function for the three charts, including an option
    // to select the time for the trading pnl
    const DropdownRisk = ({ selectedOption3, handleChange3 }) => {
        return (
            <select
                value={selectedOption3}
                onChange={handleChange3}
                style={{
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid white',
                    fontSize: '16px',
                    color: 'black',
                    margin: '10px 0',
                    width: '100%',
                    maxWidth: '300px',
                    backgroundColor: 'transparent'
                }}
            >
                <option value="risk">Risk Metrics</option>
                <option value="sharp">Sharpe Ratio</option>
            </select>
        );
    };


    // Modify handleSort to include dynamic hp handling
    // invert the asc to descend because the selectors alternate when you click
    const handleSort = (columnName) => {
        let columnField = columnName;

        if (sortedColumn === columnField) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortedColumn(columnField);
            setSortOrder("desc");
        }
    };


    const handleHpCommit = (event, newValue) => {
        setHpRaw(newValue);
        setHp(newValue)
        localStorage.setItem("hp", newValue);
    };


    //creating a slider for the data
    // Creating a slider for the data
    const time_marks = [
        {
            value: 2,
            label: '2m'
        },
        {
            value: 4,
            label: '4m'
        },
        {
            value: 6,
            label: '6m'
        },
        {
            value: 12,
            label: 'All'
        },
    ];

    const HistoricalPeriodSlider = ({ period, setPeriod }) => {
        const valueMap = { '2m': 2, '4m': 4, '6m': 6, 'All': 12 };
        const [value, setValue] = useState(valueMap[period]);

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };
        const periodMap = { '2': '2m', '4': '4m', '6': '6m', '12': 'All' };
        const handleCommit = () => {
            setPeriod(periodMap[value.toString()]);
            localStorage.setItem("historical-period", periodMap[value.toString()])
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", marginTop: "20px" }}>
                <div style={{ marginLeft: '10px', alignItems: "center" }}>
                    {value === 2 ? '2m' : value === 4 ? '4m' : value === 6 ? '6m' : value === 12 ? 'All' : ''}
                </div>
                <div style={{ width: "90%", alignItems: "center" }}>
                    <Slider
                        aria-label="Historical Period"
                        min={2}
                        max={12}
                        value={value}
                        valueLabelDisplay="off"
                        color="warning"
                        onChange={handleChange}
                        onChangeCommitted={handleCommit}
                        marks={time_marks}

                        step={null}
                        sx={{
                            '& .MuiSlider-markLabel': {
                                color: "transparent",
                                fontSize: 0,
                            },
                        }}
                    />
                </div>
            </div>
        );
    };


    const getAdjustedColumnName = (columnName) => {
        let columnField = columnName;
        let suffix = '';

        // Adjust column name based on hp for ei and ci
        if (columnName === "ci" || columnName === "ei") {

            if (regimeSettings === "bullish") {
                suffix += "_bull";
            } else if (regimeSettings === "bearish") {
                suffix += "_bear";
            }

            // If hp is less than 30, use ci_iis_(regime)[hp]
            // else use ci_(regime)_longterm[monthindex]
            if (hp < 30) {
                columnField = `${columnName}_iis${suffix}.${hp - 1}`;
            } else {
                const monthIndex = Math.floor(hp / 5 - 5) - 1;
                suffix += `_longterm.${monthIndex}`;
                columnField = `${columnName}${suffix}`;
            }
        }

        // Adjust column name based on hp for pnl
        if (columnName === "pnl15") {

            columnField = "pnl_arr.cumulative_pnls"

            if (regimeSettings === "bullish") {
                suffix += ".hp15_bull";
            } else if (regimeSettings === "bearish") {
                suffix += ".hp15_bear";
            }
            else {
                suffix += ".hp15"
            }


            // Adjust the start date based on the selected period
            switch (period) {
                case "2m":
                    suffix += ".cumulative_pnl_2m"
                    break;
                case "4m":
                    suffix += ".cumulative_pnl_4m"
                    break;
                case "6m":
                    suffix += ".cumulative_pnl_6m"
                    break;
                case "All":
                    suffix += ".cumulative_pnl_All"
                    break;
                default:
                    suffix += ".cumulative_pnl_2m"
            }

            columnField = `${columnField}${suffix}.${threshold - 55}`;

        }
        return columnField;
    };



    const renderSortIcon = (columnName) => {

        if (sortedColumn === columnName) {
            return sortOrder === "asc" ? (
                <FaSortUp style={{ cursor: 'pointer' }} onClick={() => handleSort(columnName)} />
            ) : (
                <FaSortDown style={{ cursor: 'pointer' }} onClick={() => handleSort(columnName)} />
            );
        }
        return <FaSort style={{ cursor: 'pointer' }} onClick={() => handleSort(columnName)} />;
    };


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobilePage, setMobilePage] = useState(1);
    const [settingsOpen, setSettingsOpen] = useState(false);


    const [selectedOption, setSelectedOption] = useState('tradingpnl');
    const [selectedOption2, setSelectedOption2] = useState('similarcos');
    const [selectedOption3, setSelectedOption3] = useState('risk');

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const handleChange2 = (event) => {
        setSelectedOption2(event.target.value);
    };

    const handleChange3 = (event) => {
        setSelectedOption3(event.target.value);
    };

    return (
        <>
            {/*<PublicTitle title={`${props.name}'s TradingPulse`} />*/}

            {isMobile ? (
                <>
                    <button onClick={() => setSettingsOpen(true)} style={{ padding: "10px", margin: "0px 0px 15px 0px" }}>
                        Open Settings
                    </button>
                    <Drawer anchor="right"
                        open={settingsOpen}
                        onClose={() => setSettingsOpen(false)}
                        ModalProps={{ disableScrollLock: true }}
                    >
                        <MarketpulseTopBar
                            setSearchQuery={setSearchQuery}
                            regimeSettings={regimeSettings}
                            setRegimeSettings={setRegimeSettings}
                            setSelectedIndices={setSelectedIndices}
                            setSelectedSectors={setSelectedSectors}
                            selectedIndices={selectedIndices}
                            selectedSectors={selectedSectors}
                            setSortedColumn={setSortedColumn}
                            setSortOrder={setSortOrder}
                            setCurrentPage={setCurrentPage}
                            threshold={threshold}
                            setThreshold={setThreshold}
                            minPrice={minPrice}
                            setMinPrice={setMinPrice}
                            setOnlyShowFavorites={setOnlyShowFavorites}
                            setOnlyShowETFs={setOnlyShowETFs}
                            setSelectedModel={setSelectedModel}
                            models={models}
                            isMobile={isMobile}
                            setSettingsOpen={setSettingsOpen}
                            hp={hp}
                            setHp={setHp}
                            stateSort={stateSort} setStateSort={setStateSort}
                            stateIndicator={stateIndicator} setStateIndicator={setStateIndicator}
                            stateBHorizon={stateBHorizon} setStateBHorizon={setStateBHorizon}
                            period={period}
                            setPeriod={setPeriod}
                            HistoricalPeriodSlider={HistoricalPeriodSlider}
                            hpRaw={hpRaw}
                            setHpRaw={setHpRaw}
                            handleHpCommit={handleHpCommit}
                            cumulativePNLArray={cumulativePNLArray}
                        />
                    </Drawer>
                </>
            ) : (
                <MarketpulseTopBar
                    setSearchQuery={setSearchQuery}
                    regimeSettings={regimeSettings}
                    setRegimeSettings={setRegimeSettings}
                    setSelectedIndices={setSelectedIndices}
                    setSelectedSectors={setSelectedSectors}
                    selectedIndices={selectedIndices}
                    selectedSectors={selectedSectors}
                    setSortedColumn={setSortedColumn}
                    setSortOrder={setSortOrder}
                    setCurrentPage={setCurrentPage}
                    threshold={threshold}
                    setThreshold={setThreshold}
                    minPrice={minPrice}
                    setMinPrice={setMinPrice}
                    setOnlyShowFavorites={setOnlyShowFavorites}
                    setOnlyShowETFs={setOnlyShowETFs}
                    setSelectedModel={setSelectedModel}
                    models={models}
                    isMobile={isMobile}
                    hp={hp}
                    setHp={setHp}
                    stateSort={stateSort} setStateSort={setStateSort}
                    stateIndicator={stateIndicator} setStateIndicator={setStateIndicator}
                    stateBHorizon={stateBHorizon} setStateBHorizon={setStateBHorizon}
                    overallPnl={overallPnl}
                    isLoadingSumPNL={isLoadingSumPNL}
                    mobilePage={mobilePage}
                    setMobilePage={setMobilePage}
                    period={period}
                    setPeriod={setPeriod}
                    HistoricalPeriodSlider={HistoricalPeriodSlider}
                    hpRaw={hpRaw}
                    setHpRaw={setHpRaw}
                    handleHpCommit={handleHpCommit}
                    cumulativePNLArray={cumulativePNLArray}
                />
            )}

            <div style={{ overflowX: "hide" }}>
                <div className="mktps-table">
                    <div className="mktps-header">
                        <div className="mktps-col obligor">
                            {onlyShowETFs ? "ETF" : "Company"}&nbsp;
                            <FaCircleInfo data-tooltip-id="mktps-header-tooltip" data-tooltip-content={`${onlyShowETFs ? "ETF" : "Company"} Name and Stock Ticker`} />
                            {renderSortIcon("obligor")}
                        </div>
                        {(!isMobile || mobilePage === 1) && <div className="mktps-col signals">
                            <div style={{ display: "flex", flexDirection: "row", width: "85%", alignItems: "center" }}>
                                {!isMobile && <div style={{ display: "flex", flexDirection: "column", width: "50%", alignItems: "center", marginTop: "20px", marginRight: "15px" }}>
                                    <div style={{ marginBottom: '0px' }}>
                                        {hp <= 30 ? `Hold: ${hp}d` : `Hold: ${hp / 5 - 5}m`}
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <Slider
                                            min={1}
                                            max={55}
                                            value={hpRaw}
                                            valueLabelDisplay="off"
                                            color="warning"
                                            onChange={(e) => setHpRaw(e.target.value)}
                                            onChangeCommitted={handleHpCommit}
                                            marks={hpMarks}
                                            step={null}
                                            sx={{
                                                '& .MuiSlider-markLabel': {
                                                    color: "transparent",
                                                    fontSize: 7,
                                                },
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>}
                                <div style={{ display: "flex", width: "50%" }}>
                                    {(stateIndicator == "CI" || stateIndicator == "Both") && <div style={{ margin: "auto", position: 'relative', flexDirection: "row" }}>CI&nbsp;
                                        {/*<FaCircleInfo data-tooltip-id="mktps-header-tooltip" data-tooltip-html={`<p style='max-width: 40vw'>Credit Indicator is a tool that predicts how a company's credit assets and liabilities will change compared to similar companies over a defined trading period, either 5 or 15 days. This prediction is based on excess return performance of these assets and liabilities to BAM's benchmark pool of companies in the same sector with the same credit rating, using factors like credit spreads and assumed duration. A "green" prediction suggests that the credit-related liabilities of the company are expected to become less expensive (tightening spreads), while a "red" prediction indicates they may become more expensive (wider spreads).<br />Confidence Level is a tool that indicates the model's confidence that a company will outperform its peers. For Equity Indicators (EI), this means outperforming companies in the same index, and for Credit Indicators (CI), it's about outperforming companies in the same sector and with the same credit rating. We calculate this confidence based on historical data within a specific time frame that considers how well this prediction would have worked in the past for the given trading period. The confidence score can range from 50% to 99.9%, but we only show the prediction direction for confidence levels of 55% and above. When the confidence is at 50%, it's like a coin flip – a 50-50 chance. So, we only provide a direction when we're at least 55% confident that the prediction will be correct.</p>`} />*/}
                                        <div></div>
                                        {renderSortIcon(getAdjustedColumnName('ci'))}
                                    </div>}
                                    {(stateIndicator == "EI" || stateIndicator == "Both") &&
                                        <div style={{ margin: "auto", position: 'relative', flexDirection: "row" }}>EI&nbsp;
                                            {/*<FaCircleInfo data-tooltip-id="mktps-header-tooltip" data-tooltip-html={`<p style='max-width: 40vw'>Equity Indicator is a tool that predicts how a company's stock will perform compared to it's relevant index over a defined trading period, either 5 or 15 days. This prediction is based on excess return performance, which means that it assesses how the investment is expected to outperform or underperform the relevant index. For example, it compares the performance of a company in the S&P 500 to the S&P 500 index itself. A "green" prediction suggests that the company's stock will increase in price, while a "red" prediction suggests they may decrease in price.<br />Confidence Level is a tool that indicates the model's confidence that a company will outperform its peers. For Equity Indicators (EI), this means outperforming companies in the same index, and for Credit Indicators (CI), it's about outperforming companies in the same sector and with the same credit rating. We calculate this confidence based on historical data within a specific time frame that considers how well this prediction would have worked in the past for the given trading period. The confidence score can range from 50% to 99.9%, but we only show the prediction direction for confidence levels of 55% and above. When the confidence is at 50%, it's like a coin flip – a 50-50 chance. So, we only provide a direction when we're at least 55% confident that the prediction will be correct.</p>`} />*/}
                                            <div></div>
                                            {renderSortIcon(getAdjustedColumnName('ei'))}
                                        </div>}
                                    <FaCircleInfo data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'><strong>Select your anticipated hold period-</strong> to see the trading indicator (Equity/EI or Credit/CI). <br />The chart shows the company's performance relative to it's relevant index over a that hold period</p>" />
                                </div>
                            </div>
                        </div>}
                        {/* first dropdown */}
                        {(!isMobile || mobilePage === 2) &&
                            <div className="mktps-col pnl">
                                <div style={{ display: "flex", flexDirection: "row", width: "80%", alignItems: "center" }}>
                                    {!isMobile && <div style={{ width: "40%", alignItems: "center" }}>
                                        <HistoricalPeriodSlider period={period} setPeriod={setPeriod} />
                                    </div>}
                                    <div style={{ width: "60%" }}>
                                        <DropdownChart selectedOption={selectedOption} handleChange={handleChange} />
                                    </div>
                                </div>
                                {selectedOption === "tradingpnl" && (!isMobile || isMobile && mobilePage === 1) &&
                                    <div className="tradingpnl">
                                        {/*<FaCircleInfo data-tooltip-id="mktps-header-tooltip"
                                            data-tooltip-html="<p style='max-width: 40vw'>Profit & Loss resulting from clear execution based on signals. The Profit and Loss graph shows what would have happened if you initiated a trade (either buying or selling) at the beginning of the trading day and then closed it (selling or buying) at the end of the trading day after holding it for 5 or 15 trading days. It demonstrates how your investment would have performed during this time frame.</p>" />
                                        */}
                                        <div></div>
                                        {renderSortIcon(getAdjustedColumnName(`pnl15`))}
                                    </div>}
                                {selectedOption === 'sentiment' && (!isMobile || (isMobile && mobilePage === 2)) && (
                                    <div className="sentiment">
                                        {/*<FaCircleInfo
                                            data-tooltip-id="mktps-header-tooltip"
                                            data-tooltip-html="<p style='max-width: 40vw'>Sentiment Scores measure the machine's sentiment about a company in digital media, like news, blogs, and web posts. There are three scores: one for the most positive sentiment, one for the most negative sentiment, and one that's the average of all the relevant news about the company. The scores range from -1.0 (very negative sentiment) to +1.0 (very positive sentiment). To calculate these scores, BAM uses a BERT-style NLP model. But before using the model, BAM filters through a large portion of the web, around 70% or more, from over 50,000 sources, based on what's available on Google. This filtering helps make the sentiment scores as accurate as possible.</p>"
                                            style={{ marginRight: '10px' }} // Add margin-right here
                                        />*/}
                                        <div></div>
                                        {renderSortIcon('sentiment')}
                                    </div>
                                )}
                                {selectedOption === 'newsflow' && (!isMobile || (isMobile && mobilePage === 2)) && (
                                    <div className="newsflow">
                                        {/*<FaCircleInfo
                                            data-tooltip-id="mktps-header-tooltip"
                                            data-tooltip-html="<p style='max-width: 40vw'>Volume of news regarding a company relative to itself during the course of 30 days. Normalized to maximum over last 30 days.</p>"
                                            style={{ marginRight: '10px' }} // Add margin-right here
                                        />*/}
                                        <div></div>
                                        {renderSortIcon('newsflow')}
                                    </div>
                                )}
                                <FaCircleInfo
                                    data-tooltip-id="mktps-header-tooltip"
                                    data-tooltip-html="<p style='max-width: 40vw'>Select a historical time horizon to see from the drop down choices:<ul><li><strong>Historical Active PnL-</strong> Profit & Loss resulting from trades executed based on signals</li><li><strong>Insider & Sentiment-</strong> Sentiment Scores on relevant Digital Media </li><li><strong>News Flow-</strong> Volume of news relative to itself over 30 days</li></ul></p>"
                                />
                            </div>
                        }
                        {/* second dropdown */}
                        {(!isMobile || mobilePage === 3) &&
                            <div className="mktps-col riskmetrics">
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <DropdownRisk selectedOption3={selectedOption3} handleChange3={handleChange3} />
                                </div>
                                {false && selectedOption3 === "risk" && (!isMobile || isMobile && mobilePage === 3) &&
                                    {/*<FaCircleInfo data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Value at Risk (VaR) is a common measure in trading. In stable markets with consistent changes, VaR tells you, in percentage terms, the maximum potential loss you can expect on a trade for a given time frame and risk level. It's the loss that should not be exceeded with a certain likelihood. BAM's typical VaR calculation is 95% for a one-day trading period. This means that there's a 5% chance the loss will be worse than what VaR predicts.<br />Expected Shortfall (ES) is the anticipated loss on a trade if it goes beyond the Value at Risk (VaR) level. In BAM, where VaR is set at 95% for one day, ES is the expected loss on a trade that experiences more loss than what VaR predicts within one day.<br />Peak-to-trough measures the biggest loss, in terms of a percentage, that a traded position experienced within a 100-day period. It looks at the difference between the highest value and the lowest value during that time frame.</p>" />*/ }
                                }
                                {false && selectedOption3 === 'sharp' && (!isMobile || isMobile && mobilePage === 3) &&
                                    {/*<FaCircleInfo data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Sharp ratio.</p>" />*/ }
                                }
                                <FaCircleInfo
                                    data-tooltip-id="mktps-header-tooltip"
                                    data-tooltip-html="<p style='max-width: 40vw'>Select from the drop down choices: <ul><li><strong>Risk Metrics-</strong> Value at risk, Expected Shortfall, Peek-to-Trough</li><li><strong>Sharpe Ratio-</strong> Passive and Active</li> </ul></p>"
                                />
                            </div>
                        }
                        {/* third dropdown */}
                        {(!isMobile || mobilePage === 3) &&
                            <div className="mktps-col similar">
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <DropdownRVSC selectedOption2={selectedOption2} handleChange2={handleChange2} />
                                </div>
                                {false && selectedOption2 == 'similarcos' && (!isMobile || isMobile && mobilePage === 3) &&
                                    {/*<FaCircleInfo data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Similar companies are those that perform in similar ways. BAM figures out this similarity in three ways: by looking at their stocks,  bonds, and financial ratios. For each of these, it uses different time periods and peer groupings to calculate correlation of normalized dynamics.</p>" />*/ }
                                }
                                {false && selectedOption2 == 'relval' && (!isMobile || isMobile && mobilePage === 3) &&
                                    {/*<FaCircleInfo data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Relative value.</p>" />*/ }
                                }
                                <FaCircleInfo
                                    data-tooltip-id="mktps-header-tooltip"
                                    data-tooltip-html="<p style='max-width: 40vw'>Select from the drop down choices:<ul><li><strong>Similar Companies-</strong> companies with similar market or fundamental ratios to the target</li><li><strong>Relative Value-</strong> Based on the most similar companies that presents the highest confidence will move the opposite direction</li></ul></p>"
                                />
                            </div>
                        }
                        {isMobile && <div className="mktps-col next">See More&nbsp;
                            <FaCircleInfo data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>See more information about this company.</p>" />
                        </div>}
                        <Tooltip id="mktps-header-tooltip" multiline={true} data-html={true} style={{ zIndex: "105", backgroundColor: "white", color: "black", boxShadow: "0 0 5px #D9D9D9" }} />
                        {!isMobile && (
                            <div className="mktps-col actions">Actions&nbsp;
                                <FaCircleInfo
                                    data-tooltip-id="mktps-header-tooltip"
                                    data-tooltip-html="<p style='max-width: 40vw'>Select favorite companies, create email notifications, download rich and customizable reports or historical data</p>"
                                />
                            </div>
                        )}
                    </div>
                    <div className={isLoading ? "blurThreePX" : ""} style={{ position: "relative" }}>
                        {isLoading && <div className="mktps-spinner"></div>}
                        {data.map((item, i) => (
                            <MarketpulseRow regimeSettings={regimeSettings} debtor={item._id} ticker={item.ticker} data={item} key={`${item.ticker}_${i}_${latestQueryId.current}`} threshold={threshold / 100.0} isMobile={isMobile} mobilePage={mobilePage} setMobilePage={setMobilePage} onlyShowETFs={onlyShowETFs}
                                searchFunction={setSearchQuery} hp={hp} selectedOption={selectedOption} selectedOption2={selectedOption2} selectedOption3={selectedOption3} period={period} setPeriod={setPeriod} stateIndicator={stateIndicator} stateBHorizon={stateBHorizon} />
                        ))}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TablePagination
                    count={nameCount}
                    onPageChange={(e, p) => { handlePageChange(p + 1) }}
                    page={currentPage - 1}
                    rowsPerPage={displayCount}
                    onRowsPerPageChange={(e) => {
                        setDisplayCount(e.target.value);
                        localStorage.setItem("displaycount", e.target.value);
                    }}
                    showFirstButton
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    sx={{ listStyle: "none" }}
                />
            </div>
            <div style={{ height: "30px" }}></div>
            <div style={{ position: "fixed", bottom: "10px", left: "15px" }}>
                <Popup

                    trigger={open => (
                        <button>
                            Feedback
                        </button>
                    )}
                    position="right center"
                    closeOnDocumentClick
                    modal
                >
                    <FeedbackModal type="mktps" />
                </Popup>
            </div>
        </>
    );
}

function MktpsTickerSignal(props) {
    return (
        <div className="mktps-ticker-bound">
            <div>{props.name}<span className="mktpls-ticker-info"></span></div>
            <span>bubble</span>

        </div>
    )
}

function MktpsTickerSimilar(props) {

    const navigate = useNavigate();

    function tickerClicked() {
        navigate(`/tradingpulse/${props.ticker}`)
    }
    return (
        <div className="mktps-sim-bound" onClick={tickerClicked}>
            {props.ticker}
            <br />
            Include some minimalistic chart/bubbles here
        </div>
    )
}

function MarketpulseTicker() {
    const { ticker } = useParams();
    const ticker_upper = ticker.toUpperCase();


    return (
        <>
            <PublicTitle title={ticker_upper} />
            <div style={{ display: "flex" }}>
                <div className="mktps-main">
                    <div className="mktps-bar">
                        <div style={{ display: "flex", overflowX: "auto" }}>
                            <MktpsTickerSignal name="EI" />
                            <MktpsTickerSignal name="CI" />
                            <MktpsTickerSignal name="Theme" />
                            <MktpsTickerSignal name="VaR" />
                            <MktpsTickerSignal name="ES" />
                            <MktpsTickerSignal name="PtT" />
                        </div>
                    </div>
                    <div className="mktps-bigchart">
                        <D3PNL />
                    </div>
                    <div className="mktps-bar">Other metrics</div>
                </div>
                <div className="mktps-sidebar">
                    <h4>Similar Names</h4>
                    <MktpsTickerSimilar ticker="MSFT" />
                    <MktpsTickerSimilar ticker="NVDA" />
                    <MktpsTickerSimilar ticker="SSNLF" />
                    <MktpsTickerSimilar ticker="AMZN" />
                    <MktpsTickerSimilar ticker="GOOGL" />
                </div>
            </div>

        </>
    )
}

export default function Marketpulse() {

    let location = useLocation();

    const navigate = useNavigate();

    window.document.title = "BAM.money | Tradingpulse";

    const [username, setUsername] = useState("");

    const checkIfLoggedIn = () => {
        if (("Yes" == localStorage.getItem("user-is-logged-in-bam-money"))
            && (Date.now() - (localStorage.getItem("user-time-log-in-bam-money") ? localStorage.getItem("user-time-log-in-bam-money") : 0) < 120000)) {
            setUsername(localStorage.getItem("username-bam-money"))
            return;
        }
        fetch('/api/account', {})
            .then((response) => response.json())
            .then((json) => {
                if (json.response === "registered") setUsername(json.name)
                else navigate("/api/login");
            })
            .catch(() => navigate("/api/login"));
    }

    useEffect(() => {
        checkIfLoggedIn();
    }, []);

    return (
        <>
            <PublicNavbar />

            <Routes location={location}>
                <Route path="/" element={<MarketpulseTable name={username} />} />
                <Route path="/search/company/:id" element={<SearchCompany isEtf={false} />} />
                <Route path="/search/etf/:id" element={<SearchCompany isEtf={true} />} />
                <Route path="*" element={<MarketpulseTable name={username} />} />
            </Routes>
            <PublicFooter />
        </>
    );
}
