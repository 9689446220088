import '../css/profile.css';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { TextField, Button, Checkbox, FormControlLabel, FormControl, FormHelperText, Box } from '@mui/material';

import { PublicFooter, PublicNavbar, PublicTitle } from "../components/pagestyle";

function RegistrationForm() {
    window.dataLayer.push({'event': 'registration_started'});
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        acceptTerms: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const fieldValue = type === "checkbox" ? checked : value;
    
        setFormData({
            ...formData,
            [name]: fieldValue,
        });
    };
  
    const validateForm = () => {
        const errors = {};
    
        if (!formData.firstName) {
            errors.firstName = "First Name is required.";
        }
    
        if (!formData.lastName) {
            errors.lastName = "Last Name is required.";
        }
    
        if (!formData.acceptTerms) {
            errors.acceptTerms = "You must accept the terms to continue.";
        }
    
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
  
    const handleSubmit = async (event) => {
        event.preventDefault();
        window.dataLayer.push({'event': 'registration_submitted'});
    
        if (validateForm()) {
            setIsSubmitting(true);
    
            try {
                const response = await fetch("/api/account/register", {
                    method: "POST",
                    body: JSON.stringify({
                        'firstName': formData.firstName,
                        'lastName': formData.lastName
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
        
                if (response.status === 200) {
                    window.dataLayer.push({'event': 'registration_success'});
                    
                    console.log("Account created successfully!");
                    window.location.href = '/tradingpulse';
                } else {
                    console.error("API request failed");
                }
            } catch (error) {
                console.error("Error sending the API request", error);
            }
    
            setIsSubmitting(false);
        }
    };
  
    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="12px"
                boxShadow={3}
                width="50%"
                margin="20px auto"
                border={1}
                p={5}
                gap="20px"
            >
                <h1>Create an account</h1>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    gap="20px"
                >
                    <div style={{ flex: 1 }}>
                        <TextField
                            name="firstName"
                            label="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            error={formErrors.firstName !== undefined}
                            helperText={formErrors.firstName}
                            fullWidth={true}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <TextField
                            name="lastName"
                            label="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            error={formErrors.lastName !== undefined}
                            helperText={formErrors.lastName}
                            fullWidth={true}
                        />
                    </div>
                </Box>

                <FormControl
                    required
                    error={formErrors.acceptTerms !== undefined}
                >
                    <span>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="acceptTerms"
                                    checked={formData.acceptTerms}
                                    onChange={handleChange}
                                    color="primary"
                                />
                            }
                        />
                        I accept the{" "}
                        <a href="#" style={{color: "blue", textDecoration: "none"}}>Terms and Conditions of Use</a>{", "}
                        <a href="#" style={{color: "blue", textDecoration: "none"}}>Terms of Payment</a>
                        {" "}and{" "}
                        <a href="#" style={{color: "blue", textDecoration: "none"}}>Self-Declaration Regarding Exchange of Information</a>.
                    </span>
                    <FormHelperText>{formErrors.acceptTerms}</FormHelperText>
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    fullWidth={true}
                >
                    Create Account
                </Button>
            </Box>
        </>
    );
}
  

export default function Register() {
    const navigate = useNavigate();
    window.document.title = "BAM.money | Register";

    const checkIfLoggedIn = () => {
        fetch('/api/account', {})
            .then((response) => response.json())
            .then((json) => {
                if (json.response !== "not registered") {
                    navigate("/");
                }
            })
    }

    useEffect(() => {
        checkIfLoggedIn();
      }, []);

    return (
        <>
            <PublicNavbar forceRedirectIfNoAccount={false}/>
            <PublicTitle title="Account Registration" />
            <RegistrationForm />
            <PublicFooter />
        </>
    );
};