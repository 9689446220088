import '../css/faq.css';
import { useState, useEffect } from "react";
import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";
import React from 'react';

export default function FAQ() {
    window.document.title = "BAM.money | FAQ";

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    const data1 = [
        {
            "question": "What is BAM?",
            "answer": "BAM is a provider of data and a data stack engine designed to assist users in determining corporate names of focus within the trading domain. The platform enhances trading intelligence by offering an objective driven AI with risk and performance analysis. It produces unique insights for investors in the corporate financial asset/liability space. Its primary use is for pre-trading and research into corporate opportunities. This includes analysis of recent market events and potential impacts on instrument prices, specifically credit and equity. Furthermore, BAM identifies as an independent source of alternative data and trading signals, aiming to boost and augment corporate trading knowledge."
        },
        {
            "question": "Who can use BAM?",
            "answer": "Investment and trading professionals, including: portfolio managers, traders, analysts, risk officers."
        },
        {
            "question": "Why is BAM an essential set of data for corporate related assets?",
            "answer": "Investing in corporates can be complex due to asymmetric payouts, necessitating in-depth research. While credit corporate analysts might predominantly cover around 30 corporate names, the market consists of thousands of such names. BAM steps in to streamline this process by enhancing risk management, both in terms of analysis and workflow. The platform provides users with actionable insights, allowing them to efficiently locate, compare, and understand asset information while optimizing their internal research resources allocation. With BAM, users can bypass the inefficiency of sifting through bulk email notifications and avoid spending time on unrelated news or documents, ensuring focused and relevant research."
        },
        {
            "question": "How is BAM different from other AI companies?",
            "answer": "BAM is not working towards General Artificial Intelligence nor is BAM a generative technology firm. BAM is building the future of corporate credit trading, starting with clear data insights regarding those corporates. BAM is working towards an objective driven ML engineering. It  offers a laser focused data stack. It goes afterfacts and perception affecting the trading of corporates. It is not producing an all data for all users."
        },
        {
            "question": "How is BAM paid?",
            "answer": "Our users pay monthly access fees (depending on the type of access they have). However we are still developing our business model. We have not made any final decisions about how we may charge for the product in the future. We believe BAM's data should always be directly aligned to each user's interests. We therefore think it's crucial that you are the only person who pays for it. Subscription and other forms of direct payment will be our primary, default business model."
        },
        {
            "question": "What problem does BAM solve?",
            "answer": "In the modern financial landscape, professionals like traders, portfolio managers, and risk managers are inundated with a deluge of data. The challenge is twofold: the overwhelming volume of data available and the constant evolution of tools and analytical perspectives. Simply put, while data is abundant, meaningful and actionable information is scarce. Integrating and processing every available data source in-house is an unrealistic aspiration for most, and yet, professionals need to differentiate their strategies to capture unique alpha for competitive reasons.\nBAM steps in to address these multifaceted challenges:\n- Data Overload and Noise Reduction: Amidst the daily barrage of data, discerning what's relevant is like finding a needle in a haystack. BAM specializes in sifting through vast data volumes, filtering out the noise, and presenting users with concise, actionable insights.\n- Customization and Unique Alpha: Recognizing the industry's need for distinctive strategies, BAM offers highly customizable signals. Users have the flexibility to input their time series, allowing BAM's proprietary models to generate unique and non-shareable signals, ensuring that the derived insights remain exclusive and provide a competitive edge.\n- Transparency and Verification: Trust is paramount in the financial industry. To bolster confidence in its outputs, BAM not only provides processed insights but also offers access to raw data, both historical and real-time. This ensures users can verify and cross-reference information, maintaining transparency and promoting informed decision-making.\nIn essence, BAM addresses the critical pain points of modern financial professionals by transforming overwhelming and often chaotic data streams into tailored, actionable, and trustworthy insights, thereby bridging the gap between abundant data and valuable information."
        },
        {
            "question": "How does BAM solve those problems?",
            "answer": "BAM solves those problems by producing cutting edge fundamental data models that are flexibly connected with other models or data sets. Also, it creates and leverages network economies, financial engineering and workflow efficiencies. In short, BAM's solution lies in its fusion of cutting-edge technology, user-centric design, transparency, and its ability to provide bespoke insights through advanced proprietary models."
        }
    ]

    const data2 = [
        {
            "question": "What type of information will you find on BAM? What is the main focus?",
            "answer": "BAM provides data tailored to address challenges encountered by investment professionals when navigating corporate-related risks. This data encompasses various factors including recent price performance, financial ratios, and news sentiment, which are synthesized to produce trading signals and establish statistical correlations across numerous corporate entities. Additionally, BAM offers users the capability to access curated headlines, offering a concise view of prevailing market narratives. Beyond this, the platform delivers trading signals that span diverse strategies and asset classes, serving as a comprehensive tool for investment insights."
        },
        {
            "question": "What differentiates BAM from other data platforms?",
            "answer": `Differentiating BAM from other data platforms boils down to several distinct characteristics:
            
First BAM offers a laser focused data stack. It focuses on corporate trading insights: facts and perception over them. It is not producing an all data for all users.

Source and Use of Data: Unlike many platforms that rely on structured data, BAM primarily sources its data from unstructured mediums. This approach captures nuanced insights not easily discernible through traditional methods.

Target Audience and Focus: BAM caters to a broad spectrum of the financial world, including analysts and trading professionals (buyers, and sellers). Its primary focus is to offer insights on credit related aspects of corporate names. Its aim is to offer a secure platform that bolsters market comprehension of potential opportunities. With features like advanced search, in-depth statistical research, and timely alert modules, users can navigate the financial terrain more confidently and efficiently.

Actionability and Customization: The data BAM offers is not just raw information. It's actionable, verifiable, and customizable, granting users the ability to extract unique alpha that aligns with their specific needs.

Purpose and Intuitiveness: BAM serves as an initial gateway for users to obtain swift digital insights that might elude manual analysis. Despite its intuitive interface, it's not merely another entry-level fintech tool. The platform is built upon deep-rooted financial expertise, ensuring that market participants receive refined and actionable fintech information.

Transparency and Independence: BAM stands apart as a neutral entity. It's not a broker-dealer and harbors no vested interest in the outcome, price, or spread of any transaction. While it does cater to financial groups, its services remain unbiased. At its core, BAM is a fully independent provider, offering unswayed data without endorsing or recommending any specific assets.

In summary, BAM distinguishes itself through its unique data sourcing, actionable insights, financial expertise, unwavering independence, and tailored utilities designed for a diverse financial audience.`        
        },
        {
            "question": "What technology does BAM use? Can it hallucinate?",
            "answer": "BAM's technological infrastructure is a blend of proprietary and modern machine learning tools. At its core, BAM has developed its own proprietary statistical models tailored to its specific requirements and the nuances of the financial domain. However BAM uses AI/ML to industrialize its data production. It then leverages the data to improve the output of insights.\nAI/ML Frameworks: BAM integrates a range of artificial intelligence and machine learning methodologies:\n- For sentiment processing, transformers are employed, which are state-of-the-art deep learning models, offering a nuanced understanding of text and sentiments.\n- Backpropagation, a fundamental concept in neural networks, is used for trading signals, ensuring accuracy and adaptability.\n- Hierarchical planning is leveraged for backtesting, promoting methodical and structured evaluations of trading strategies.\nIntegration and Open Source Tools: While BAM maintains its core proprietary edge, it embraces third-party tools and open-source technologies to bolster its capabilities:\n- An LLM wrapper and LLM APIs facilitate seamless integration and data handling.\n- The platform relies on non-relational databases for flexibility and scalability.\n- Importantly, BAM exercises due diligence by thoroughly testing and validating any open-source models (both in terms of code and training) it incorporates.\nGenerative Technology: A notable aspect of BAM's technology is its non-reliance on generative technology. This ensures that the data, signals, sentiment, and similarity scores it produces are rooted in real insights, eliminating the risk of generating unwarranted or 'hallucinated' data."
        },
        {
            "question": "How is data delivered?",
            "answer": "BAM's primary user interface is through its webapp, but an API is also available for more integrated applications or for those seeking to embed BAM's capabilities into their own platforms.\nThe Personal Risk Assistant enhances user accessibility, available both on the webapp and on mobile devices\nFor those needing historical data, BAM offers a streamlined solution by making it accessible via AWS's Simple Storage Service (S3), a reliable and scalable cloud storage solution.\nIn essence, BAM's technological landscape is a harmonious blend of proprietary innovations, modern AI/ML techniques, and robust third-party tools, all tailored to provide a comprehensive, reliable, and user-friendly platform for its users."
        },
        {
            "question": "What data does BAM use to create data insights?",
            "answer": "BAM data pipeline is fed using publicly available web, market and regulatory data, as well as our own proprietary sources of data. Its Personal Risk Analyst uses LLMs only to understand questions posed by users and help conveying BAM quantitative insights into easily readable sentences. However the quantitative elements of all answers, especially those related to predictions (trading indicators, similar risk factors, risk metrics, etc) are produced based on public well filtered sources."
        },
        {
            "question": "Does BAM use conversations with its Personal Risk Analyst? How?",
            "answer": "User interactions, as reflected in conversation logs, assist BAM in evolving and enhancing its capabilities. However, these logs are kept confidential and are not shared with any third party unless explicitly directed by the user during the dialogue. This approach ensures that the Personal Risk Analyst can securely offer improved information to users. Moreover, users retain the option to disseminate information they consider pertinent to their stakeholders, paving the way for a more tailored user experience."
        },
        {
            "question": "How robust is BAM's system performance?",
            "answer": "BAM is hosted on the highly scalable Amazon Web Services servers ensuring users the most reliable and fastest access globally. The application is based on the Node.js environment and developed with high-efficiency search and query algorithms ensuring lightning-fast server response times."
        }
    ]

    function parseAnswer(input, outerIndex) {
        const lines = input.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={`faq-frag-${outerIndex}-${index}`}>
            {line}
            {index !== lines.length - 1 && <br />}
            </React.Fragment>
        ));
    }

    return (
        <>
            <PublicNavbar />
            <PublicTitle title="Frequently Asked Questions" />
            <h1>About BAM</h1>
            <div className="faq-dropdown">
                {data1.map((faq, index) => (
                    <div
                        className={`faq-item ${activeIndex === index ? 'faq-item-active' : ''}`}
                        key={`faq-${index}`}
                        onClick={() => toggleFAQ(index)}
                    >
                        <h3 className="faq-question">{faq.question} <h1 className="faq-icon">+</h1></h3>
                        <p className="faq-answer">{activeIndex === index && parseAnswer(faq.answer, index)}</p>
                    </div>
                ))}
            </div>
            <h1>Data, Models, and Technical Processes</h1>
            <div className="faq-dropdown">
                {data2.map((faq, index) => (
                    <div
                        className={`faq-item ${activeIndex === index ? 'faq-item-active' : ''}`}
                        key={`faq-${index}`}
                        onClick={() => toggleFAQ(index)}
                    >
                        <h3 className="faq-question">{faq.question} <h1 className="faq-icon">+</h1></h3>
                        <p className="faq-answer">{activeIndex === index && parseAnswer(faq.answer, index)}</p>
                    </div>
                ))}
            </div>
            <PublicFooter />
        </>
    );
};
