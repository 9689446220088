import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SECTable } from "../components/marketpulseComponents/SECTable";
import D3PNL from "../d3Charts/d3PNL";
import D3Sentiment from "../d3Charts/d3Sentiment";
import D3NewsFlow from "../d3Charts/d3NewsFlow";
import D3SignalHeatMap from "../d3Charts/d3SignalHeatMap";
import { SimilarityTable } from "../components/marketpulseComponents/SimilarityTable";
import { MFC } from "../components/marketpulseComponents/MFC";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import { PublicTitle } from "../components/pagestyle";
import '../css/marketpulse.css';
import LinkIcon from '@mui/icons-material/Link';
import D3PriceTarget from "../d3Charts/d3PriceTargets";
import '../css/AnalystTable.css'
// import { DataGrid, GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid';




export default function SearchCompany(props) {
    const location = useLocation();
    const priceTargetsRef = useRef(null);
    const params = useParams();
    const id = params.id ? params.id : "";
    const isEtf = props.isETF;
    const [ticker, setTicker] = useState("");
    const [chartTypeSection1, setChartTypeSection1] = useState("PNL");
    const [chartTypeSection2, setChartTypeSection2] = useState("SEC");
    const [period, setPeriod] = useState("2m");

    const [newsFlowData, setNewsFlowData] = useState([0, 0]);
    const [pnlData, setPNLData] = useState([]);
    const [filteredPNLData, setFilteredPNLData] = useState([]);
    const [sentimentData, setSentimentData] = useState([]);
    const [insiderTradingData, setInsiderTradingData] = useState({ "previous_year_percent": 0, "current_year_percent": 0, "previous_year_shares": [0, 0], "current_year_shares": [0, 0] });
    const [similarity, setSimilarity] = useState(null);
    const [contributions, setContributions] = useState(null);
    const [antiRisk, setAntiRisk] = useState(null);
    const [features, setFeatures] = useState(null);
    const [ssDate, setSSDate] = useState("01-01-1970");
    const [sector, setSector] = useState([]);
    const [sicCode, setSicCode] = useState([]);
    const [index, setIndex] = useState([]);
    const [threshold, setThreshold] = useState(localStorage.getItem("threshold") ? localStorage.getItem("threshold") : 55.0);
    const [searchQuery, setSearchQuery] = useState('');
    const [secData, setSECData] = useState(null);
    const [companyName, setCompanyName] = useState(params.ticker);
    const [priceTargets, setPriceTargets] = useState(null);
    const [cumulativePNL, setCumulativePNL] = useState(null);

    const [dnData, setdnData] = useState(null)

    function initializeRow(data, period) {
        if (!data || data.length === 0) {
            return;
        }

        const endDate = new Date();
        let startDate = new Date(endDate);

        const cumulative_pnl = dnData.pnl_arr.cumulative_pnls.hp15[`cumulative_pnl_${period != '1y' ? period : 'All'}`][0];
        setCumulativePNL(cumulative_pnl);
        // Adjust the start date based on the selected period
        switch (period) {
            case "2m":
                startDate.setMonth(endDate.getMonth() - 2);
                break;
            case "4m":
                startDate.setMonth(endDate.getMonth() - 4);
                break;
            case "6m":
                startDate.setMonth(endDate.getMonth() - 6);
                break;
            case '1y':
                startDate.setFullYear(endDate.getFullYear() - 1);
            case "All":
                startDate = new Date(0); // Start from the earliest possible date
                break;
            default:
                startDate.setMonth(endDate.getMonth() - 1);
        }

        const filteredPNL = data.filter((entry) => {
            let entryDate = new Date(entry.date);
            return entryDate >= startDate && entryDate <= endDate;
        });

        setFilteredPNLData(filteredPNL);
        return filteredPNL;
    }

    const parseSentiData = (inputData) => {
        if (!inputData.hsum) inputData.hsum = Array(30).fill("(no summary)");
        if (!inputData.asum) inputData.asum = Array(30).fill("(no summary)");
        if (!inputData.lsum) inputData.lsum = Array(30).fill("(no summary)");

        const data = [];
        const length = inputData.high.length;
        const days = [];
        const endDate = new Date(inputData.ssdate);
        for (let i = 0; i < length; i++) {
            const currentDate = new Date(endDate);
            currentDate.setDate(endDate.getDate() - i);
            days.push(currentDate);
        }

        for (let i = 0; i < length; i++) {
            var high = (inputData.high[i] === 10 ? null : inputData.high[i]);
            var avg = (inputData.avg[i] === 10 ? null : inputData.avg[i]);
            var low = (inputData.low[i] === 10 ? null : inputData.low[i]);

            if (high < avg) {
                high = (1 + 4 * avg) / 5;
            }
            if (low > avg) {
                low = (-1 + 4 * avg) / 5;
            }
            data.push({
                date: (new Date(days[i])).toISOString(),
                high,
                avg,
                low,
                hlink: inputData.hlink[i],
                alink: inputData.alink[i],
                llink: inputData.llink[i],
                hsum: parseSum(inputData.hsum[i]),
                asum: parseSum(inputData.asum[i]),
                lsum: parseSum(inputData.lsum[i])
            });
        }
        return data;
    };

    function parseSum(sum) {
        if (sum) {
            return sum.replaceAll("COMMA_REPLACEMENT_STR", ",");
        } else {
            return "(no summary)";
        }
    }

    const navigate = useNavigate();

    const handleChildElementClick = (e) => {
        e.stopPropagation();
    };

    function clickPNL() {
        setChartTypeSection1("PNL");
    }

    function clickPeriod(value) {
        setPeriod(value);
        initializeRow(pnlData, value); // Use the new period value here
    }

    function clickSentiment() {
        setChartTypeSection1("Sentiment");
    }

    function clickNewsFlow() {
        setChartTypeSection1("NewsFlow");
    }


    const parsePNLData = (pnlDataArrayRaw) => {
        const pnlDataArray = [];
        for (let i = 0; i < pnlDataArrayRaw['hp15'].length; i++) {
            pnlDataArray.push({
                date: pnlDataArrayRaw['hp15'][i]["date"],
                ei: pnlDataArrayRaw['hp15'][i]["ei15"],
                price: pnlDataArrayRaw['hp15'][i]["price"],
                release_date: pnlDataArrayRaw['hp15'][i]["release_date15"],
                return: pnlDataArrayRaw['hp15'][i]["return15"],
                actual_price_change: pnlDataArrayRaw['hp15'][i]["actual_price_change15"] || pnlDataArrayRaw['hp15'][i]["actual_price_change5"],
            });
        }
        return pnlDataArray;
    };

    useEffect(() => {
        if (location.hash === '#price-targets') {
            const element = document.getElementById('price-targets');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash, priceTargetsRef.current]);
    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`/api/marketpulse/search_by_id?is_etf=${isEtf ? "true" : "false"}&id=${id}`);
            const responseData = await response.json();
            setdnData(responseData)
            setSECData(responseData.sec_data);
            setPNLData(parsePNLData(responseData.pnl_arr));
            setSSDate(responseData.SS_Date);
            const sentimentObject = {
                high: responseData.high_score_latest30,
                hlink: responseData.high_link_latest30,
                hsum: responseData.high_summary_latest30,
                avg: responseData.avg_score_latest30,
                alink: responseData.avg_link_latest30,
                asum: responseData.avg_summary_latest30,
                low: responseData.low_score_latest30,
                llink: responseData.low_link_latest30,
                lsum: responseData.low_summary_latest30,
                ssdate: responseData.SS_Date
            };
            setSentimentData(parseSentiData(sentimentObject));
            setInsiderTradingData(responseData.insider_trading_yearly_percent_change);
            setNewsFlowData(responseData.articles_count_latest30);
            setSimilarity(responseData.similarity);
            setContributions(responseData.contributions);
            setFeatures(responseData.interpret_percentages);
            setAntiRisk(responseData.anti_risk_dn_10);
            setSector(responseData.sector);
            setSicCode(responseData.SICCode);
            setIndex(responseData.Index);

            setTicker(responseData.ticker);
            setCompanyName(responseData.debtor);
            setPriceTargets(responseData.price_targets.map((item, index) => {
                const matched_doc = responseData.matchedAnalysts.find((itemm) => itemm.name == item.analystName)
                const ticker_specific_doc = matched_doc.dn_specific.find((itemm) => itemm.ticker == responseData.ticker)
                return {
                    ...item,
                    'id': index,
                    'publishedDate': new Date(item['publishedDate']),
                    'global_accuracy': matched_doc && item.analystName ? matched_doc['bayesian_accuracy'] : NaN,
                    'ticker_accuracy': ticker_specific_doc && item.analystName ? ticker_specific_doc['bayesian_accuracy'] : NaN,
                    'bam_accuracy': ticker_specific_doc && item.analystName ? ticker_specific_doc['bayesian_accuracy_bam'] : NaN,
                    'bayesian_accuracy_bam_buy_analyst_buy': ticker_specific_doc && item.analystName ? ticker_specific_doc['bayesian_accuracy_bam_buy_analyst_buy'] : NaN,
                    'bayesian_accuracy_bam_buy_analyst_sell': ticker_specific_doc && item.analystName ? ticker_specific_doc['bayesian_accuracy_bam_buy_analyst_sell'] : NaN,
                    'bayesian_accuracy_bam_sell_analyst_buy': ticker_specific_doc && item.analystName ? ticker_specific_doc['bayesian_accuracy_bam_sell_analyst_buy'] : NaN,
                    'bayesian_accuracy_bam_sell_analyst_sell': ticker_specific_doc && item.analystName ? ticker_specific_doc['bayesian_accuracy_bam_sell_analyst_sell'] : NaN,
                }
            }));
            var ei = null
            var ci = null

            // old 15 or 5 suffix
            var suffix = 15
            var ssuffix = "_iis"


            let hp = 15
            try {
                if (!Number.isNaN(responseData.lstm["ei" + ssuffix][hp - 1])) ei = responseData.lstm["ei" + ssuffix][hp - 1];
            }
            catch {
                ei = 0.50;
            }
            setFilteredPNLData(initializeRow(pnlData, period));
        }
        fetchData();

    }, [id, isEtf]);

    useEffect(() => {
        initializeRow(pnlData, period); // Initialize filtered PNL data based on the period after pnlData is set
    }, [pnlData, period]);

    const formatDate = (date) => new Date(date).toLocaleDateString('en-US');
    const formatPercentage = (value) => value ? `${(value * 100).toFixed(2)}%` : 'NaN';

    return (
        <>
            <br />
            <PublicTitle title={`${ticker} | ${companyName}`} />
            {ticker !== "" && (
                <div className="mktps-chart-container">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className="mktps-section" style={{ flex: 1 }}>
                            <div className="mktps-chart-selector">
                                <button className={chartTypeSection1 === "PNL" ? "highlighted-button" : ""} onClick={clickPNL}
                                    data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Profit & Loss resulting from clear execution based on signals. The trading rule is simple and straightforward: buy/green dot (or sell/red) at the opening based on the signal on day 1, then sell (or buy) at the closing of the holding period (5 or 15 days). Repeat this process each day, making a buy (or sell) decision, followed by a 5 or 15-day wait, and then execute a sell (or buy) at the closing.</p>"
                                >Trading PNL</button>
                                <button className={chartTypeSection1 === "Sentiment" ? "highlighted-button" : ""} onClick={clickSentiment}
                                    data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Sentiment Scores measure the machine's sentiment about a company in digital media, like news, blogs, and web posts. There are three scores: one for the most positive sentiment, one for the most negative sentiment, and one that's the average of all the relevant news about the company. The scores range from -1.0 (very negative sentiment) to +1.0 (very positive sentiment). To calculate these scores, BAM uses a BERT-style NLP model. But before using the model, BAM filters through a large portion of the web, around 70% or more, from over 50,000 sources, based on what's available on Google. This filtering helps make the sentiment scores as accurate as possible.</p>"
                                >
                                    Sentiment
                                </button>
                                <button className={chartTypeSection1 === "NewsFlow" ? "highlighted-button" : ""} onClick={clickNewsFlow}
                                    data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Volume of news regarding a company relative to itself during the course of 30 days. Normalized to maximum over last 30 days.</p>"
                                >
                                    News Flow
                                </button>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {chartTypeSection1 === "PNL" ? (<>
                                    <div className="mktps-chart-selector">
                                        <button className={period === "2m" ? "highlighted-button" : ""} onClick={() => clickPeriod("2m")}
                                            data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Sentiment Scores measure the machine's sentiment about a company in digital media, like news, blogs, and web posts. There are three scores: one for the most positive sentiment, one for the most negative sentiment, and one that's the average of all the relevant news about the company. The scores range from -1.0 (very negative sentiment) to +1.0 (very positive sentiment). To calculate these scores, BAM uses a BERT-style NLP model. But before using the model, BAM filters through a large portion of the web, around 70% or more, from over 50,000 sources, based on what's available on Google. This filtering helps make the sentiment scores as accurate as possible.</p>"
                                        >
                                            2m
                                        </button>
                                        <button className={period === "4m" ? "highlighted-button" : ""} onClick={() => clickPeriod("4m")}
                                            data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Volume of news regarding a company relative to itself during the course of 30 days. Normalized to maximum over last 30 days.</p>"
                                        >
                                            4m
                                        </button>
                                        <button className={period === "6m" ? "highlighted-button" : ""} onClick={() => clickPeriod("6m")}
                                            data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Volume of news regarding a company relative to itself during the course of 30 days. Normalized to maximum over last 30 days.</p>"
                                        >
                                            6m
                                        </button>
                                        <button className={period === "1y" ? "highlighted-button" : ""} onClick={() => clickPeriod("1y")}
                                            data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Volume of news regarding a company relative to itself during the course of 30 days. Normalized to maximum over last 30 days.</p>"
                                        >
                                            1y
                                        </button>
                                        <button className={period === "All" ? "highlighted-button" : ""} onClick={() => clickPeriod("All")}
                                            data-tooltip-id="mktps-header-tooltip" data-tooltip-html="<p style='max-width: 40vw'>Volume of news regarding a company relative to itself during the course of 30 days. Normalized to maximum over last 30 days.</p>"
                                        >
                                            All
                                        </button>
                                    </div>

                                    <div style={{ height: "600px", marginTop: "15px" }}><D3PNL data={filteredPNLData} threshold={0.5} cumulativePNL={cumulativePNL} /></div>
                                    <div style={{ height: "200px" }}>
                                        <D3PriceTarget data={filteredPNLData} priceTargetsData={priceTargets} threshold={0.5} />
                                    </div>
                                </>) : null}
                                {chartTypeSection1 === "Sentiment" ? (
                                    <D3Sentiment data={sentimentData} insider_trading_data={insiderTradingData} />
                                ) : null}
                                {chartTypeSection1 === "NewsFlow" ? (
                                    <D3NewsFlow data={newsFlowData} date={ssDate} />
                                ) : null}
                            </div>
                        </div>
                        <div className="mktps-chart-content" style={{ flex: 1, flexDirection: "column", display: 'flex', padding: 10 }}>
                            {<SimilarityTable data={similarity} antiRiskData={antiRisk} searchFunction={setSearchQuery} />}

                            <div style={{ height: "30px", display: 'flex', flexDirection: 'row', margin: 10 }}>
                                <div style={{ width: '30px' }}>CI:</div> <D3SignalHeatMap data={dnData.lstm.ci_iis.concat(dnData.lstm.ci_longterm.slice(1))} recs={dnData.lstm.recommend_ci}/>
                            </div>
                            <div style={{ height: "30px", display: 'flex', flexDirection: 'row', margin: 10 }}>
                                <div style={{ width: '30px' }}>EI:</div> <D3SignalHeatMap data={dnData.lstm.ei_iis.concat(dnData.lstm.ei_longterm.slice(1))} recs={dnData.lstm.recommend_ei}/>
                            </div>

                            {<MFC data={contributions} antiRiskData={features} searchFunction={setSearchQuery} />}
                                                                        {/* MFC table */}
                                                                        {dnData && dnData.interpret_confidence && dnData.interpret_confidence.interpret_percentages && (
                                <MFC data={dnData.interpret_confidence.interpret_percentages} />
                            )}

                        </div>
                        
                    </div>
                    <div className="mktps-section" style={{ marginTop: 20 }}>
                        <div className="mktps-chart-content">
                            {chartTypeSection2 === "SEC" ? <SECTable data={secData} sector={sector} sicCode={sicCode} index={index} /> : null}
                        </div>
                    </div>
                    <h3>Analysts Price Targets</h3>
                    <TableContainer ref={priceTargetsRef} id="price-targets" className="table-container">
                        <Table size="small" aria-label="a dense table" className="table">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: "ffffff" }}>
                                    <TableCell align="center"><strong>Date</strong></TableCell>
                                    <TableCell align="center"><strong>Name</strong></TableCell>
                                    <TableCell align="center"><strong>Global Accuracy</strong></TableCell>
                                    <TableCell align="center"><strong>Ticker Accuracy</strong></TableCell>
                                    <TableCell align="center"><strong>BAM Accuracy</strong></TableCell>
                                    <TableCell align="center"><strong>Buy/Buy Accuracy</strong></TableCell>
                                    <TableCell align="center"><strong>Buy/Sell Accuracy</strong></TableCell>
                                    <TableCell align="center"><strong>Sell/Buy Accuracy</strong></TableCell>
                                    <TableCell align="center"><strong>Sell/Sell Accuracy</strong></TableCell>
                                    <TableCell align="center"><strong>Price Target</strong></TableCell>
                                    <TableCell align="center"><strong>Price when posted</strong></TableCell>
                                    <TableCell align="center"><strong>URL</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {priceTargets.map((row, row_index) => (
                                    <TableRow key={`sim_row_${row_index}`} sx={{ backgroundColor: row_index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}>
                                        <TableCell align="center">{formatDate(row.publishedDate)}</TableCell>
                                        <TableCell align="center">
                                            {row.analystName ? (
                                                <Link to={`/analyst/${row.analystName}`} style={{ color: 'black', textDecoration: 'none' }}>
                                                    {row.analystName}
                                                </Link>
                                            ) : null}
                                        </TableCell>
                                        <TableCell align="center">{formatPercentage(row.global_accuracy)}</TableCell>
                                        <TableCell align="center">{formatPercentage(row.ticker_accuracy)}</TableCell>
                                        <TableCell align="center">{formatPercentage(row.bam_accuracy)}</TableCell>
                                        <TableCell align="center">{formatPercentage(row.bayesian_accuracy_bam_buy_analyst_buy)}</TableCell>
                                        <TableCell align="center">{formatPercentage(row.bayesian_accuracy_bam_buy_analyst_sell)}</TableCell>
                                        <TableCell align="center">{formatPercentage(row.bayesian_accuracy_bam_sell_analyst_buy)}</TableCell>
                                        <TableCell align="center">{formatPercentage(row.bayesian_accuracy_bam_sell_analyst_sell)}</TableCell>
                                        <TableCell align="center">{row.priceTarget}</TableCell>
                                        <TableCell align="center">{row.priceWhenPosted}</TableCell>
                                        <TableCell align="center">
                                            <a href={row.newsURL} style={{ color: 'black' }}>
                                                <LinkIcon />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </>
    );
}
